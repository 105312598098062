import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { EMPTY, Observable, mergeMap, of, tap } from 'rxjs';
import { ControlPageFacade, FeatureFlagsFacade, IMaintenanceWindow, SnackBarFacade } from '@ra-state';
import { HttpMethod } from '@auth0/auth0-angular';
import { DateTime } from 'luxon';
import { ConfigService } from '@rockwell-automation-inc/service';
import { Configuration } from '@app/models/config.model';

@Injectable()
export class MaintenanceWindowInterceptor implements HttpInterceptor {
  maintenanceWindow: IMaintenanceWindow;

  // styling breaks when we place this obervavble inside the intercept function
  getMaintenanceWindowSub = this.featureFlagsFacade.maintenanceWindow$
    .pipe(tap((maintenanceWindow) => (this.maintenanceWindow = maintenanceWindow)))
    .subscribe();

  constructor(
    private featureFlagsFacade: FeatureFlagsFacade,
    private snackBarFacade: SnackBarFacade,
    private configService: ConfigService<Configuration>,
    private controlPageFacade: ControlPageFacade
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(
      mergeMap(() => {
        const csApiRequest = this.configService.config.isApiCall(request.url);
        if (!csApiRequest || !this.maintenanceWindow.isActive) {
          return next.handle(request);
        }
        // block all requests except GET or custom header allow_during_maintenance during maintenance window
        if (request.method === HttpMethod.Get || Boolean(request.headers.get('allow_during_maintenance')) === true) {
          return next.handle(request);
        }
        this.controlPageFacade.setLoading(false);
        this.snackBarFacade.displayMessage({
          message: `Our system is currently undergoing maintenance. Please try after ${this.maintenanceWindow.to.toLocaleString(
            DateTime.DATETIME_MED
          )}`,
          type: 'Warning',
        });
        return EMPTY;
      })
    );
  }
}
