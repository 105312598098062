import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SELECTABLE_ROLES } from '@core/common-constants';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-ag-switch-button',
  styleUrls: ['./ag-switch-button.component.scss'],
  templateUrl: './ag-switch-button.component.html',
})
export class AgSwitchButtonComponent implements ICellRendererAngularComp {
  public cellValue: string;
  fontStyleControl = new UntypedFormControl();
  public data: ICellRendererParams;
  isOwnerRole: boolean = false;
  roles = SELECTABLE_ROLES;
  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.data = params;
    this.cellValue = this.getValueToDisplay(params);
    this.isOwnerRole = params.data.role === 'Owner';
  }

  onValChange(val: string): void {
    this.data.node.setDataValue('role', val);
    this.cellValue = val;
    this.data.api.refreshCells();
  }

  getValueToDisplay(params: ICellRendererParams): string {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
