/**
 * @description
 * A method that needs to be overwritten to recieve ag-grid render actions
 *
 * @usageNotes
 * The following snippet shows how a component can implement this interface to
 * define its own initialization method.
 * cellRendererParams: {
 *  buttonNames: ['RESEND', 'CANCEL'],
 *  agRenderClickHandler: this.agRenderClickHandler.bind(this),
 * },
 */
export declare interface AgRenderHandler {
  agRenderClickHandler(action: string, data: any): void;
  agRenderIconClickHandler(action: string, data: any): void;
}

export enum AG_RENDER_ACTIONS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum AG_RENDER_BUTTON_OPTIONS {
  NONE = 'none',
  FIRST_BUTTON = 'first_button',
  SECOND_BUTTON = 'second_button',
  BOTH_BUTTONS = 'both_buttons',
}
