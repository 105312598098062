import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { UserDataFacade, UserPreferences } from '@ra-state';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-welcome-dialog',
  styleUrls: ['./welcome-dialog.component.scss'],
  templateUrl: './welcome-dialog.component.html',
})
export class WelcomeDialogComponent implements AfterContentChecked {
  title = 'Welcome to FactoryTalk Hub!';
  description = '';
  createOrgBnLabel = 'Create NEW organization';
  joinOrgBnLabel = 'Join EXISTING organization';
  closeBnLabel = 'SKIP';

  userData: UserPreferences;
  userLoaded$ = this.userDataFacade.userData$.pipe(
    tap((userData) => {
      this.userData = userData;
    })
  );

  hasOnlyPersonalTenant$ = this.userDataFacade.hasOnlyPersonalTenant$.pipe(
    tap((hasOnlyPersonalTenant) => {
      if (hasOnlyPersonalTenant) {
        this.closeBnLabel = 'skip';
        this.description =
          'Start your journey with FactoryTalk Hub by joining an existing organization, or creating a new organization for you and your colleagues.';
      } else {
        this.closeBnLabel = 'continue';
        this.description =
          'Start your journey with FactoryTalk Hub and get access to your available apps, hubs and profile';
      }
    })
  );

  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private router: Router,
    private userDataFacade: UserDataFacade,
    private changeDetector: ChangeDetectorRef
  ) {}

  getInitials(fullName: string): string {
    return fullName
      ? fullName
          .split(' ')
          .map((nameList) => nameList[0])
          .join('')
      : '';
  }

  createOrg(): void {
    this.dialogRef.close();
    this.router.navigate(['/organization/create']);
  }

  joinOrg(): void {
    this.dialogRef.close();
    this.router.navigate(['/organization/join']);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
