import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { AG_RENDER_ACTIONS } from '../ag-grid.models';

@Component({
  styleUrls: ['ag-delete-icon-render.component.scss'],
  templateUrl: './ag-delete-icon-render.component.html',
})
export class AgDeleteIconRenderComponent implements ICellRendererAngularComp {
  params: any;
  AG_RENDER_ACTIONS = AG_RENDER_ACTIONS;

  agInit(params: any): void {
    this.params = params;
  }

  // Optional - Return true if you want to manage a cell refresh yourself, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create  a new component in its place
  // with the new values.
  refresh(): boolean {
    return false;
  }

  iconAction(action: AG_RENDER_ACTIONS): void {
    this.params.agRenderIconClickHandler(action, this.params.data);
  }
}
