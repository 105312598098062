import { Action, createReducer, on } from '@ngrx/store';
import { SnackBarMessage } from '../lemans-app.model';
import { displayMessage } from './snackbar.action';

export const snackbarInitialState: SnackBarMessage = {};

const snackBarData = createReducer(
  snackbarInitialState,
  on(
    displayMessage,
    (state: SnackBarMessage, { payload }): SnackBarMessage => ({
      ...state,
      data: payload,
    })
  )
);
export function snackbarDataReducer(state: SnackBarMessage, action: Action): SnackBarMessage {
  return snackBarData(state, action);
}
