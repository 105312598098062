<div class="welcome-dialog">
  <div mat-dialog-content class="welcome-dialog-content">
    <div *ngIf="userLoaded$ | async" class="big-circle-user">{{ getInitials(userData.name) }}</div>
    <div class="title">{{ title }}</div>
    <div class="sub-title">{{ description }}</div>
  </div>
  <div mat-dialog-actions class="welcome-dialog-actions">
    <div>
      <div class="button-group" *ngIf="hasOnlyPersonalTenant$ | async">
        <ra-ui-outlined-button label="{{ createOrgBnLabel }}" (click)="createOrg()"></ra-ui-outlined-button>
        <ra-ui-main-button label="{{ joinOrgBnLabel }}" (click)="joinOrg()"> </ra-ui-main-button>
      </div>
    </div>
    <button id="welcome-modal-skip-continue" class="modal-button" (click)="close()">
      {{ closeBnLabel }}
    </button>
  </div>
</div>
