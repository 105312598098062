import { Injectable } from '@angular/core';
import { ModalService } from '@core/modal/modal.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { openAlertDialog, openCatalogDialog, openConfirmDialog, openWelcomeDialog } from './modal.action';

@Injectable({
  providedIn: 'root',
})
export class ModalEffects {
  openConfirmDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openConfirmDialog),
        switchMap((payload) => {
          const modalContent = payload.payload;
          const modalResult$ = this.modalService
            .openConfirmDialog({
              OKButtonText: modalContent.OKButtonText,
              body: modalContent.body,
              cancelButtonText: modalContent.cancelButtonText,
              header: modalContent.header,
              options: modalContent.options,
            })
            .confirmed$();
          return combineLatest([modalResult$, of(modalContent.onConfirm)]);
        }),
        map(([result, onConfirm]) => {
          if (result && result !== false && onConfirm) {
            onConfirm();
          }
        })
      );
    },
    { dispatch: false }
  );

  openAlertDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openAlertDialog),
        switchMap((payload) => {
          const modalContent = payload.payload;
          const modalResult$ = this.modalService
            .openAlertDialog({
              OKButtonText: modalContent.OKButtonText || 'OK',
              body: modalContent.body,
              header: modalContent.header,
            })
            .confirmed$();
          return combineLatest([modalResult$, of(modalContent.onConfirm)]);
        }),
        map(([_result, onConfirm]) => {
          if (onConfirm) {
            onConfirm();
          }
        })
      );
    },
    { dispatch: false }
  );

  openWelcomeDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openWelcomeDialog),
        map(() => {
          this.modalService.openWelcomeDialog().confirmed$();
        })
      );
    },
    { dispatch: false }
  );

  openCatalogDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openCatalogDialog),
        switchMap((payload) => {
          const modalContent = payload.payload;
          const modalResult$ = this.modalService
            .openCatalogDialog({
              OKButtonText: modalContent.OKButtonText || 'OK',
              body: modalContent.body,
              tenantId: modalContent.tenantId,
              header: modalContent.header,
            })
            .confirmed$();
          return combineLatest([modalResult$]);
        })
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private modalService: ModalService) {}
}
