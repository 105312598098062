import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { CatalogCode, UserDataFacade } from '@ra-state';
import { debounceTime, map, Observable, shareReplay, startWith, switchMap } from 'rxjs';
import { DataService } from '@servicesV2/data.service';
import {
  MatLegacySelectionList as MatSelectionList,
  MatLegacyListOptionCheckboxPosition as MatListOptionCheckboxPosition,
} from '@angular/material/legacy-list';
import { FormControl } from '@angular/forms';

/** TO DO: reuse ContentModalComponent instead of this component */
@Component({
  selector: 'app-catalog-dialog',
  templateUrl: './catalog-dialog.component.html',
  styleUrls: ['./catalog-dialog.component.scss'],
})
export class CatalogDialogComponent implements OnInit {
  @ViewChild('catSelection') catSelection: MatSelectionList;

  searchText = new FormControl();

  position: MatListOptionCheckboxPosition = 'before';

  selectedCodesControl = new FormControl();

  catalogCodeList$: Observable<CatalogCode[]> = this.dataService.getFakeCatalogCodes$().pipe(
    map((data) => {
      data.sort((first, second) => first.name.localeCompare(second.name));
      return data;
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      body: string;
      cancelButtonText: string;
      OKButtonText: string;
      tenantId: string;
      cancelButtonVisible: boolean;
    },
    private userDataFacade: UserDataFacade,
    private dataService: DataService,
    private dialogRef: MatDialogRef<CatalogDialogComponent>
  ) {}

  search$ = this.searchText.valueChanges.pipe(
    startWith(null),
    debounceTime(200),
    switchMap((res: string) => {
      if (!res) {
        return this.catalogCodeList$;
      }
      res = res.toLowerCase();
      return this.catalogCodeList$.pipe(
        map((codes) =>
          codes.filter(
            (it) =>
              it.catalogCode.toLowerCase().indexOf(res) >= 0 ||
              it.name.toLowerCase().indexOf(res) >= 0 ||
              it.serviceKind?.toLowerCase().indexOf(res) >= 0
          )
        )
      );
    })
  );

  selectionChange(options: any): void {
    const option = options[0];
    let value = this.selectedCodesControl.value || [];
    if (option.selected) {
      value.push(option.value);
    } else {
      value = value.filter((it) => it !== option.value);
    }
    this.selectedCodesControl.setValue(value);
  }

  ngOnInit(): void {
    this.selectedCodesControl.setValue([]);
  }

  cancel(): void {
    this.close(false);
  }

  close(value): void {
    this.dialogRef.close(value);
  }

  ok(): void {
    const selectedCatalogCodes = this.selectedCodesControl.value;
    this.userDataFacade.createSelectedFakeEntitlements(selectedCatalogCodes, this.data.tenantId);
    this.close(true);
  }
}
