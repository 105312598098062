import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../lemans-app.model';
import { clearAgGridRefresh } from './ag-grid.action';
import { selectRefreshAgGrid } from './ag-grid.selector';

@Injectable({
  providedIn: 'root',
})
export class AgGridFacade {
  refreshAgGrid$ = this.store$.select(selectRefreshAgGrid);

  constructor(private store$: Store<AppState>) {}

  clearAgGrid(): void {
    this.store$.dispatch(clearAgGridRefresh());
  }
}
