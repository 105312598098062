<div *ngIf="showtitle" class="title m-25-0">Build Information</div>
<div class="parent-content">
  <div class="content">
    <div #versionInfo>
      <div class="build-section error-details" *ngIf="showError && (errorContext$ | async) as errorContext">
        <div class="sub-title info">Error Detail</div>
        <div class="info"><span class="text-bold">URL:</span> {{ errorContext.url }}</div>
        <div class="info"><span class="text-bold">CorrelationId:</span> {{ errorContext.correlationId }}</div>
        <div class="info"><span class="text-bold">Message:</span> {{ errorContext.message }}</div>
      </div>

      <div class="build-section context">
        <div class="sub-title info">Context</div>
        <div class="info" *ngIf="userDataFacade.userData$ | async as userData; else nouser">
          <span class="text-bold">User:</span> {{ userData.email }}/{{ userData.userId }}
        </div>
        <ng-template #nouser>
          <div class="info"><span class="text-bold">User:</span> User info not available</div>
        </ng-template>
        <div class="info" *ngIf="userDataFacade.currentTenant$ | async as tenantContext; else notenant">
          <span class="text-bold">Org:</span> {{ tenantContext.name }}/{{ tenantContext.id }}
        </div>
        <ng-template #notenant>
          <div class="info"><span class="text-bold">Org:</span> Org info not available</div>
        </ng-template>
      </div>

      <div class="build-section build" *ngIf="versionInfos$ | async as versionInfos">
        <div class="sub-title info">Build</div>
        <div class="info"><span class="text-bold">Portal:</span> {{ versionInfos.portalVersion }}</div>
        <div class="info">
          <span class="text-bold">Notifications API:</span> {{ versionInfos.notificationsVersion }}
        </div>
        <div class="info"><span class="text-bold">CS API:</span> {{ versionInfos.apiVersion }}</div>
      </div>
    </div>
    <div class="actions">
      <ra-ui-main-button id="copy-build-info" label="Copy" icon="ra-icon-ide-md-copy" (click)="cdkCopyToClipboard()">
      </ra-ui-main-button>
      <ra-ui-outlined-button
        *ngIf="showBackButton"
        id="back"
        label="Back"
        icon="ra-icon-ide-md-chevron-left"
        (click)="back()"
      >
      </ra-ui-outlined-button>
    </div>
  </div>
</div>
