import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  ControlPageFacade,
  GainsightFacade,
  ModalFacade,
  Preferences,
  Role,
  ROLES_WITH_CREDIT_LEDGER_ACCESS,
  ROLES_WITH_ENTITLEMENT_ACCESS,
  ROLES_WITH_MANAGE_ACCESS,
  SignalRFacade,
  UserDataFacade,
  UserFeedback,
  FeatureFlagsFacade,
  BannerType,
} from '@ra-state';
import {
  filter,
  fromEvent,
  map,
  Observable,
  of,
  scan,
  switchMap,
  take,
  takeWhile,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import {
  NavItem,
  NavItemActions,
  NavBarTheme,
  LanguageItem,
  NoConentApiResponse,
  Banner,
} from '@rockwell-automation-inc/common-utils';
import { FeedbackComponent } from '../shared/feedback-modal/feedback.component';
import { LoggerService } from '@servicesV2/logger.service';
import { Router, RoutesRecognized } from '@angular/router';
import { IBannerButtonClickedEvent, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { CommonService, ConfigService, SignalrService } from '@rockwell-automation-inc/service';
import { CommonConstants } from '@core/common-constants';
import { Configuration } from '@app/models/config.model';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concatLatestFrom } from '@ngrx/effects';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../auth/auth.service';

class ShellComponentConstants {
  static HUBMANAGER_ACCESS_MANAGEMENT_LINK_ID = 'access-management';
  static HUBMANAGER_ACCESS_MANAGEMENT_LINK_ROUTE = 'access-mngmt';
  static HUBMANAGER_VIEW_ENTITLEMENTS_LINK_ID = 'entitlements';
  static HUBMANAGER_VIEW_ENTITLEMENTS_LINK_ROUTE = 'entitlement';
  static HUBMANAGER_INVITE_USERS_LINK_ID = 'invite-users';
  static HUBMANAGER_INVITE_USERS_LINK_ROUTE = ShellComponentConstants.HUBMANAGER_INVITE_USERS_LINK_ID;
  static HUBMANAGER_APPROVE_USERS_LINK_ID = 'approve-users';
  static HUBMANAGER_APPROVE_USERS_LINK_ROUTE = ShellComponentConstants.HUBMANAGER_APPROVE_USERS_LINK_ID;
  static HUBMANAGER_INVITATION_MANAGEMENT_LINK_ID = 'invitation-management';
  static HUBMANAGER_INVITATION_MANAGEMENT_LINK_ROUTE = ShellComponentConstants.HUBMANAGER_INVITATION_MANAGEMENT_LINK_ID;
  static MAINTENANCE_WINDOW_PRE_WARNING_DAYS = 5;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-shell',
  styleUrls: ['./shell.component.scss'],
  templateUrl: './shell.component.html',
})
export class ShellComponent implements OnInit {
  navbarTheme = NavBarTheme.DARK;
  OPENICON = 'ra-icon-ide-md-open';

  shouldShowBreadcrumb: boolean = false;
  dashboardPage = '/dashboard';
  redeemPage = '/redeem';
  buildInfoPage = '/about/buildInfo';
  timeoutBannerMessage: string;
  bannerItems: Banner[] = [];
  helpItems: NavItem[];
  navItems: NavItem[];
  notificationAction$: Observable<any>;
  mfaResetEnable = false;
  resetmfa$: Observable<NoConentApiResponse> = this.commonService.resetmfa$();

  displayBreadcrumb$ = this.router.events.pipe(
    filter((event) => event instanceof RoutesRecognized),
    map((event: RoutesRecognized) => {
      this.shouldShowBreadcrumb =
        event.urlAfterRedirects !== this.dashboardPage && !event.urlAfterRedirects?.includes(this.redeemPage);
    }),
  );

  userID$ = this.userDataFacade.userID$;
  hasOnlyPersonalTenant$ = this.userDataFacade.hasOnlyPersonalTenant$;
  currentTenantId$ = this.userDataFacade.currentTenantId$;
  updatedTenantName$ = this.userDataFacade.updatedTenantName$;
  userName$ = this.userDataFacade.userName$;
  currentCustomProvisioningData$ = this.userDataFacade.currentCustomProvisioningData$;

  effectiveRoles$ = this.userDataFacade.effectiveRoles$;
  availableLinks$: Observable<any>;

  // Session Timeout
  countDownTimer$: Observable<number>;
  countDownTime: number;

  disableTokenNegativeBalanceNotification$ = this.userDataFacade.currentPreferences$.pipe(
    switchMap((preference: Preferences) => {
      if (preference.disableTokenNotification) {
        return of();
      }

      return this.userDataFacade.tokenBalance$;
    }),
    tap((balance) => {
      if (balance && balance < 0) {
        this.showTokenNegativeBalanceBanner();
      } else {
        this.closeBanner(BannerType.NegativeTokenBalance);
      }
    }),
  );

  updateOrganizations$ = this.commonService.updateOrganizations$;

  signalRDisconnected$ = this.signalRFacade.hubDisconnected$.pipe(
    tap(async (state) => {
      // TODO: why are we checking auth0 state - we are disconnected!
      const isSessionActive = await this.authService.checkAuth0Session();
      if (isSessionActive) {
        this.modalFacade.openAlertDialog({
          header: 'Aw, snap! Disconnected',
          body: `Disconnected from the server. Page will be refreshed to attempt reconnection. Error: ${state.lastError}`,
          onConfirm: () => window.location.reload(),
        });
      } else {
        this.authService.logout();
      }
    }),
  );

  signalRConnected$ = this.signalRFacade.isHubConnected$;

  private APP_LINKS: NavItem[] = [];
  private HUBMANAGER_LINKS: NavItem[] = [];
  private ORGANIZATION_LINKS: NavItem[] = [];
  private hubManagerItems: NavItem[];

  private organizationLinks: NavItem[];

  enableThemeSwitcher$: Observable<any> = this.featureFlagsFacade.getFlagValue$('enable_theme_switcher');

  techSupportURL$ = this.userDataFacade.userData$.pipe(
    withLatestFrom(this.featureFlagsFacade.getFlagValue$('enable_tech_support')),
    filter(([_userData, _enableTechSupport]) => Boolean(_enableTechSupport)),
    map(([_userData, _enableTechSupport]) => {
      const userEmail = _userData.email;
      return (
        CommonConstants.CONTACT_SUPPORT_URL +
        encodeURIComponent(userEmail) +
        this.configService.config.techSupportUrlPath
      );
    }),
  );

  helpMenuToOpen$ = this.controlPageFacade.helpMenuToOpen$.pipe(
    filter((navItem) => Boolean(navItem?.id)),
    map((navItem) => navItem),
  );

  supportedLanguages = this.configService.config.supportedLanguages || [];

  displayLanguageToggle$ = this.featureFlagsFacade.getFlagValue$('enable_language_switcher').pipe(
    map((languageSwitcherEnabled) => {
      return Boolean(languageSwitcherEnabled);
    }),
  );

  languageChanged$ = this.translate.onLangChange.asObservable().pipe(
    tap(() => {
      this.createNavItems();
    }),
  );
  preferredLangauge$ = this.userDataFacade.preferredLanguage$.pipe(
    tap((value) => {
      this.logger.log('preferredLangauge', value);
      this.translate.use(String(value));
      const selectedLang = this.supportedLanguages.filter((item) => {
        return item.langCode === value;
      })[0];
      this.selectedLang = selectedLang;
      this.createNavItems();
    }),
  );

  sessionTimedOut$ = this.controlPageFacade.sessionTimedOut$.pipe(
    filter((sessionTimedOut) => sessionTimedOut),
    map((sessionTimedOut) => {
      this.showSessionTimeoutBanner();
      this.countDownTimer$ = this.startCountDownTimer$();
      return sessionTimedOut;
    }),
  );
  click$ = fromEvent(document, 'click').pipe(
    concatLatestFrom(() => [this.controlPageFacade.sessionTimedOut$]),
    filter(([, sessionTimedOut]) => sessionTimedOut),
    tap(() => {
      this.controlPageFacade.extendSession();
      this.closeBanner(BannerType.SessionTimeout);
    }),
  );

  closeBanner(bannerType: BannerType): void {
    const banner = this.bannerItems.find((banner) => banner.id === bannerType);
    if (banner) {
      this.hideBanner(banner);
    }
  }

  hideBanner(banner: Banner): void {
    this.bannerItems = this.bannerItems.filter((bannerItem) => bannerItem.id !== banner.id);
  }

  setCountDownTime(countDownTime: number): void {
    this.countDownTime = countDownTime;
  }

  startCountDownTimer$(): Observable<number> {
    const countDownTime = this.countDownTime || CommonConstants.sessionTimeout;
    const sessionTimeoutBanner = this.bannerItems.find((banner) => banner.id === BannerType.SessionTimeout);
    return timer(0, 1000).pipe(
      scan((acc) => acc - 1, countDownTime),
      takeWhile((x) => x >= 0 && sessionTimeoutBanner !== undefined && sessionTimeoutBanner.show),
      tap((timer) => timer === 0 && this.controlPageFacade.logoutSession()),
      map((seconds) => seconds * 1000),
      tap((timer) => {
        this.timeoutBannerMessage = `Session timeout in ${this.datePipe.transform(
          timer,
          'mm:ss',
          'UTC',
        )}s due to inactivity. Click anywhere to continue your session`;
        if (sessionTimeoutBanner) {
          sessionTimeoutBanner.message = this.timeoutBannerMessage;
        }
      }),
    );
  }

  addBannerToTray(banner: Banner): void {
    if (this.bannerItems.findIndex((x) => x.id === banner.id) === -1) {
      this.bannerItems.push(banner);
    }
  }

  showSessionTimeoutBanner(): void {
    const sessionTimeOutBanner: Banner = {
      show: true,
      message: this.timeoutBannerMessage,
      config: {
        showDefaultIcon: true,
        showCloseIcon: false,
        useNotificationBackground: false,
        notificationType: NotificationType.Warning,
      },
      buttons: [
        {
          label: 'Continue',
        },
      ],
      raIcon: '',
      id: BannerType.SessionTimeout,
    };
    this.addBannerToTray(sessionTimeOutBanner);
  }

  selectedLang: LanguageItem = this.supportedLanguages[0];

  constructor(
    private dialog: MatDialog,
    private modalFacade: ModalFacade,
    private controlPageFacade: ControlPageFacade,
    private userDataFacade: UserDataFacade,
    private logger: LoggerService,
    private signalRFacade: SignalRFacade,
    private router: Router,
    private gainsightFacade: GainsightFacade,
    public authService: AuthenticationService,
    public signalRService: SignalrService,
    public commonService: CommonService,
    private featureFlagsFacade: FeatureFlagsFacade,
    private configService: ConfigService<Configuration>,
    public translate: TranslateService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.displayBreadcrumb$.subscribe();
    this.shouldShowBreadcrumb = this.router.url !== this.dashboardPage && !this.router.url?.includes(this.redeemPage);
  }

  setAvailableLinks(): void {
    this.availableLinks$ = this.effectiveRoles$.pipe(
      withLatestFrom(this.currentTenantId$, this.userID$),
      tap(([effectiveRoles, currentTenantId, userId]) => {
        const tenantRole = effectiveRoles?.find((role) => role.resourceId === currentTenantId);
        const isPersonalTenant = currentTenantId === userId;
        if (!isPersonalTenant) {
          this.generateHubManagerLinks(currentTenantId, effectiveRoles);
        } else {
          this.generateHubManagerPersonalTenantLinks();
        }
        this.generateOrganizationLinks(isPersonalTenant, tenantRole?.role);

        this.navItems = [...this.APP_LINKS, ...this.HUBMANAGER_LINKS, ...this.ORGANIZATION_LINKS];
      }),
    );
  }
  translationArr = [{ key: 'homeMenuTitle', text: 'fthub.factorytalk_hub_home' }];

  getTranslation(key: string): string {
    return this.translate.instant(key);
  }

  createNavItems(): void {
    this.translationArr.forEach((el) => {
      this[el.key] = this.getTranslation(el.text);
    });
    const ftHubTourKey = this.getTranslation(marker('fthub.tour'));
    this.hubManagerItems = [
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.manage_user_access')), //marker function is used to mark the translation keys so we can extract all the keys with one command
        id: ShellComponentConstants.HUBMANAGER_ACCESS_MANAGEMENT_LINK_ID,
        route: ShellComponentConstants.HUBMANAGER_ACCESS_MANAGEMENT_LINK_ROUTE,
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.view_entitlements')),
        id: ShellComponentConstants.HUBMANAGER_VIEW_ENTITLEMENTS_LINK_ID,
        route: ShellComponentConstants.HUBMANAGER_VIEW_ENTITLEMENTS_LINK_ROUTE,
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.invite_users')),
        id: ShellComponentConstants.HUBMANAGER_INVITE_USERS_LINK_ID,
        route: ShellComponentConstants.HUBMANAGER_INVITE_USERS_LINK_ROUTE,
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.approve_users')),
        id: ShellComponentConstants.HUBMANAGER_APPROVE_USERS_LINK_ID,
        route: ShellComponentConstants.HUBMANAGER_APPROVE_USERS_LINK_ROUTE,
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.manage_organization_invites')),
        id: ShellComponentConstants.HUBMANAGER_INVITATION_MANAGEMENT_LINK_ID,
        route: ShellComponentConstants.HUBMANAGER_INVITATION_MANAGEMENT_LINK_ROUTE,
      },
    ];

    this.organizationLinks = [
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.create_organization')),
        id: 'create-org',
        route: 'organization/create',
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.edit_organization')),
        id: 'edit-org',
        route: 'organization/edit',
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.join_organization')),
        id: 'join-org',
        route: 'organization/join',
      },
      {
        action: NavItemActions.self,
        displayName: this.getTranslation(marker('fthub.universal_credits_balance')),
        id: 'ledger',
        route: 'universalcredits/ledger',
      },
    ];

    this.helpItems = [
      {
        action: NavItemActions.blank,
        raIconClass: this.OPENICON,
        displayName: this.getTranslation(marker('fthub.online_help')),
        id: 'online-help',
        route: 'https://www.rockwellautomation.com/docs/en/factorytalk-hub/current/factorytalk_hub-ditamap.html',
      },
      {
        action: NavItemActions.blank,
        raIconClass: this.OPENICON,
        displayName: ftHubTourKey === 'fthub.tour' ? 'FactoryTalk Hub Tour' : ftHubTourKey,
        id: 'fthub-tour',
        route: 'https://rockwellautomation.storylane.io/share/cpq3be8iji67',
      },
      {
        action: NavItemActions.blank,
        raIconClass: this.OPENICON,
        displayName: this.getTranslation(marker('fthub.getting_started')),
        id: 'getting-started',
        route:
          'https://www.rockwellautomation.com/docs/en/factorytalk-hub/current/factorytalk_hub-ditamap/ftcs_getting_started.html',
      },
      {
        action: 'custom',
        raIconClass: this.OPENICON,
        displayName: this.getTranslation(marker('fthub.contact_support')),
        id: 'contact-support',
      },
      {
        action: 'custom',
        raIconClass: 'ra-icon-ide-lg-feedback',
        displayName: this.getTranslation(marker('fthub.provide_feedback')),
        id: 'provide-feedback',
      },
      {
        action: NavItemActions.blank,
        raIconClass: this.OPENICON,
        displayName: this.getTranslation(marker('fthub.release_notes')),
        id: 'release-notes',
        route:
          'https://www.rockwellautomation.com/docs/en/factorytalk-hub/current/factorytalk_hub-ditamap/rel_notes(1).html',
      },
      {
        children: [
          {
            action: NavItemActions.blank,
            raIconClass: 'ra-icon-ide-sm-controller-configuration',
            displayName: this.getTranslation(marker('fthub.build_information')),
            id: 'build-information',
          },
          {
            action: NavItemActions.blank,
            raIconClass: this.OPENICON,
            displayName: this.getTranslation(marker('fthub.legal')),
            id: 'legal',
            route: 'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices.html',
          },
          {
            action: NavItemActions.blank,
            raIconClass: this.OPENICON,
            displayName: this.getTranslation(marker('fthub.license_agreement')),
            id: 'license-agreement',
            route:
              'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/software-cloud-services-agreement.html',
          },
          {
            action: NavItemActions.blank,
            raIconClass: this.OPENICON,
            displayName: this.getTranslation(marker('fthub.privacy_policy')),
            id: 'privacy-policy',
            route:
              'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/privacy-and-cookiespolicy.html',
          },
          {
            action: NavItemActions.blank,
            raIconClass: this.OPENICON,
            displayName: this.getTranslation(marker('fthub.terms_of_use')),
            id: 'terms-of-use',
            route:
              'https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/terms-and-conditionsofaccess.html',
          },
        ],
        displayName: this.getTranslation(marker('fthub.about')),
        expander: true,
        id: 'about',
      },
    ];
    this.setAvailableLinks();
  }

  helpItemClicked(item: NavItem): void {
    if (item.id === 'provide-feedback') {
      this.openFeedbackComponent();
    } else if (item.id === 'build-information') {
      this.router.navigate([this.buildInfoPage]);
    } else {
      window.open(item.route, item.action);
    }
  }

  openFeedbackComponent(): void {
    this.dialog
      .open(FeedbackComponent, { disableClose: true, width: '560px' })
      .afterClosed()
      .subscribe((feedback: UserFeedback) => {
        if (feedback) {
          this.userDataFacade.shareFeedback(feedback);
        }
      });
  }

  generateHubManagerLinks(tenantId: string | undefined, tenantEffectiveRoles): void {
    // TO DO: change approach to enclose code inside of an if instead of return
    if (tenantEffectiveRoles?.length === 0) {
      this.logger.error('atleast one tenant effective role is required to generate hubmanager links');
      return;
    }
    const tenantRole = tenantEffectiveRoles?.find(
      (item) => item.resourceId === tenantId && item.resourceType === 'Tenant',
    )?.role;
    const hasManageAccessOnAtleastOneResource = tenantEffectiveRoles?.some((item) =>
      ROLES_WITH_MANAGE_ACCESS.includes(item.role),
    );
    const hubManagerLinks: NavItem[] = [];
    this.hubManagerItems.forEach((link) => {
      const canAddLink =
        (link.id === ShellComponentConstants.HUBMANAGER_VIEW_ENTITLEMENTS_LINK_ID &&
          ROLES_WITH_ENTITLEMENT_ACCESS.includes(tenantRole)) ||
        ((link.id === ShellComponentConstants.HUBMANAGER_ACCESS_MANAGEMENT_LINK_ID ||
          link.id === ShellComponentConstants.HUBMANAGER_APPROVE_USERS_LINK_ID) &&
          ROLES_WITH_MANAGE_ACCESS.includes(tenantRole)) ||
        ((link.id === ShellComponentConstants.HUBMANAGER_INVITE_USERS_LINK_ID ||
          link.id === ShellComponentConstants.HUBMANAGER_INVITATION_MANAGEMENT_LINK_ID) &&
          hasManageAccessOnAtleastOneResource);

      if (canAddLink) {
        hubManagerLinks.push(link);
      }
    });

    if (hubManagerLinks.length > 0) {
      this.HUBMANAGER_LINKS = [
        {
          children: hubManagerLinks,
          displayName: this.getTranslation(marker('fthub.hub_manager')),
          id: 'hub-manager',
          isHeading: true,
        },
      ];
    } else {
      this.HUBMANAGER_LINKS = [];
    }
  }

  generateHubManagerPersonalTenantLinks(): void {
    const hubManagerLinks: NavItem[] = [];
    this.hubManagerItems.forEach((link) => {
      const canAddLink = link.id === ShellComponentConstants.HUBMANAGER_VIEW_ENTITLEMENTS_LINK_ID;
      if (canAddLink) {
        hubManagerLinks.push(link);
      }
    });

    if (hubManagerLinks.length > 0) {
      this.HUBMANAGER_LINKS = [
        {
          children: hubManagerLinks,
          displayName: this.getTranslation(marker('fthub.hub_manager')),
          id: 'hub-manager',
          isHeading: true,
        },
      ];
    } else {
      this.HUBMANAGER_LINKS = [];
    }
  }

  generateOrganizationLinks(isPersonalTenant, tenantRole?: Role): void {
    if (!tenantRole) {
      this.logger.error('tenant role is required to generate organization links');
      return;
    }
    const orgLinks: NavItem[] = [];
    this.organizationLinks.forEach((link) => {
      switch (link.id) {
        case 'edit-org':
          if (!isPersonalTenant && ROLES_WITH_MANAGE_ACCESS.includes(tenantRole)) {
            orgLinks.push(link);
          }
          break;
        case 'ledger':
          if (!isPersonalTenant && ROLES_WITH_CREDIT_LEDGER_ACCESS.includes(tenantRole)) {
            orgLinks.push(link);
          }
          break;
        default:
          orgLinks.push(link);
      }
    });

    this.ORGANIZATION_LINKS = [
      {
        children: orgLinks,
        displayName: this.getTranslation(marker('fthub.organization')),
        id: 'organization',
        isHeading: true,
      },
    ];
  }

  changeOrg(event: any): void {
    this.logger.log('dispatch organization change');
    this.userDataFacade.tenantChange(event.tenantId);
  }

  menuItemClicked(item: NavItem): void {
    this.router.navigate([item.route]);
  }

  logoutClicked(): void {
    this.gainsightFacade.resetSession();
    this.authService.logout();
  }

  goToHome(): void {
    this.router.navigate([this.dashboardPage]);
  }

  bannerButtonClicked($event: IBannerButtonClickedEvent): void {
    if ($event.button.data) {
      this.router.navigate([$event.button.data.redirectUrl]);
    }
  }

  showTokenNegativeBalanceBanner(): void {
    const negativeTokenBalanceBanner: Banner = {
      show: true,
      message:
        'Looks like your organization ran out of universal credits. Application functionality may be reduced until more universal credits are applied.',
      config: {
        showDefaultIcon: true,
        showCloseIcon: true,
        useNotificationBackground: true,
        notificationType: NotificationType.Warning,
      },
      buttons: [
        {
          label: 'Allocate credits',
          data: {
            redirectUrl: '/entitlement',
          },
        },
      ],
      raIcon: 'ra-icon-ide-sm-token',
      id: BannerType.NegativeTokenBalance,
    };
    this.addBannerToTray(negativeTokenBalanceBanner);
  }

  showMaintenanceWindowBanner(message: string, notificationType: NotificationType, bannerIcon: string): void {
    const maintenanceBanner: Banner = {
      show: true,
      message: message,
      config: {
        showDefaultIcon: true,
        showCloseIcon: true,
        useNotificationBackground: true,
        notificationType: notificationType,
      },
      buttons: [],
      raIcon: bannerIcon,
      id: BannerType.MaintenanceWindow,
    };
    this.addBannerToTray(maintenanceBanner);
  }

  maintenanceWindow$ = this.featureFlagsFacade.maintenanceWindow$.pipe(
    tap((maintenanceWindow) => {
      const start = maintenanceWindow.from.toLocaleString(DateTime.DATETIME_MED);
      const end = maintenanceWindow.to.toLocaleString(DateTime.DATETIME_MED);
      if (maintenanceWindow.isActive) {
        const msg = `Maintenance in progress: FactoryTalk Hub is going through scheduled maintenance until ${end}. The portal is currently in read-only mode.`;
        this.showMaintenanceWindowBanner(msg, NotificationType.Warning, 'ra-icon-ide-sm-root-asset');
      } else if (maintenanceWindow.startsWithinDays(ShellComponentConstants.MAINTENANCE_WINDOW_PRE_WARNING_DAYS)) {
        this.showMaintenanceWindowBanner(
          `Upcoming Scheduled Maintenance: ${start} to ${end}. FactoryTalk Hub portal will be available in read-only mode during this time. Access to other services will not be affected.`,
          NotificationType.Info,
          'ra-icon-ide-sm-alarm',
        );
      } else {
        // MW was disabled
        this.closeBanner(BannerType.MaintenanceWindow);
      }
    }),
  );

  maintenanceWindowCompleted$ = this.featureFlagsFacade.maintenanceWindowCompleted$.pipe(
    tap(() => {
      this.logger.log('hiding mw banner');
      this.closeBanner(BannerType.MaintenanceWindow);
    }),
  );

  userId$ = this.authService.user$.pipe(
    take(1),
    filter((user) => user?.userId !== undefined),
    tap((user) => {
      this.commonService.setUserId(user?.userId);
      this.logger.log('user', user);
      this.mfaResetEnable = user?.customClaims?.mfa_otp === 'true';
    }),
  );

  orgs$ = this.commonService.getOrganizations$();

  showOrganizationSwitcher$ = this.controlPageFacade.showOrganizationSwitcher$;

  initializeNavbar$ = this.commonService.initializeNavbar$('');
  getCurrentTenantName$ = this.commonService.getCurrentTenantName$();
  getCurrentTenantLogo$ = this.commonService.getCurrentTenantLogo$();
  getAccountMenuData$ = this.commonService.getAccountMenuData$();

  getUserNotifications$ = this.commonService.getUserNotifications$().pipe(map((data) => data.records));
  getNotifications$ = this.commonService.getNotifications$();
  refreshNotificationsGrid$ = this.commonService.refreshNotificationsGrid$().pipe(
    switchMap(() => this.commonService.getUserNotificationCounts$()),
    switchMap(() => this.commonService.getUserNotifications$()),
  );
  getUnreadNotificationsCount$ = this.commonService.getUnreadNotificationsCount$();
  getUserNotificationCounts$ = this.commonService.getUserNotificationCounts$();
  getTotalNotificationsCount$ = this.commonService.getTotalNotificationsCount$();
  notificationsInProgress$ = this.commonService.getNotificationsInProgress$();

  onHubConnected$ = this.signalRFacade.isHubConnected$;

  onEulaAccepted$ = this.controlPageFacade.isEulaAccepted$;

  signalRUserNotifications$ = this.signalRService.userNotification$.pipe(
    withLatestFrom(this.commonService.getNotifications$(), this.commonService.getUnreadNotificationsCount$()),
    tap(([notificationOnSignalR, notifications, unreadNotificationCount]) => {
      const existingNotification = notifications.find((notification) => notification.id === notificationOnSignalR.id);

      // If the notification is not already in the list and is unread, add it to the list
      if (!existingNotification && notificationOnSignalR.isRead === false) {
        const updatedNotifications = [notificationOnSignalR, ...notifications].slice(0, 10);
        this.commonService.setNotification(updatedNotifications);
      }

      // If the notification is already in the list, update the isRead property with the comming from signal R
      if (existingNotification) {
        const updatedNotifications = notifications.map((notification) => {
          if (notification.id === notificationOnSignalR.id) {
            notification.isRead = notificationOnSignalR.isRead;
            return notification;
          }
          return notification;
        });
        this.commonService.setNotification(updatedNotifications);
      }

      if (unreadNotificationCount > 0 && notificationOnSignalR.isRead === true) {
        unreadNotificationCount -= 1;
        this.commonService.setUnreadNotificationsCount(unreadNotificationCount);
      } else if (notificationOnSignalR.isRead === false) {
        unreadNotificationCount += 1;
        this.commonService.setUnreadNotificationsCount(unreadNotificationCount);
      }
    }),
  );

  notificationService$ = this.commonService.getUserNotifications$.bind(this.commonService);

  homeMenuTitle: string;

  notificationAction(data: any): void {
    this.notificationAction$ = this.commonService.handleNotificationAction$(data);
  }

  get logoTypeThemeClass(): string {
    return this.navbarTheme === NavBarTheme.DARK ? 'ra-logo-ft-hub-dark' : 'ra-logo-ft-hub-light';
  }

  setLogoTheme(theme: NavBarTheme): void {
    this.navbarTheme = theme;
  }

  setSelectedLang(selectedLangItem: LanguageItem): void {
    this.selectedLang = selectedLangItem;
    this.userDataFacade.languageChange(this.selectedLang.langCode);
    this.translate.use(this.selectedLang.langCode);
  }

  resetMFA(): void {
    // Handle reset event
  }
}
