<ng-container>
  <div class="no-data-div">
    <span class="custom-icon"><i class="ra-icon-ide-sm-search ra-common-icon"></i></span>
  </div>
  <ra-ui-empty-state
    id="emptyStateComponent"
    [title]="params['title']"
    [message]="params['content']"
  ></ra-ui-empty-state>
</ng-container>
