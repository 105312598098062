import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
@Component({
  selector: 'app-dropdownform',
  styleUrls: ['./dropdownform.component.scss'],
  templateUrl: './dropdownform.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DropdownformComponent implements OnInit {
  @Input() label: string;
  @Input() data: any;
  @Input() controlName: string;
  @Input() multiple: boolean;
  @Input() dependentControl: boolean = false;
  @Input() depentFormControlValues: any[] = [];
  public form: UntypedFormGroup;
  public control: UntypedFormControl;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
    this.control = this.getControls(this.controlName);
  }

  get Data(): any {
    return this.data[this.controlName];
  }

  getControls(controlName): any {
    return <UntypedFormControl>this.form.get(controlName);
  }

  selection(val): void {
    if (this.dependentControl && this.depentFormControlValues.length > 0) {
      this.depentFormControlValues.forEach((item) => {
        if (item['selector'] === val) {
          this.data[item.dependentFormControlName] = JSON.parse(JSON.stringify(item.value));
        }
      });
    }
  }
}
