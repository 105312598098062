<ra-ui-empty-state
  [title]="title"
  [message]="message"
  [imageSource]="'assets/images/lg-alert.svg'"
  [buttons]="buttons"
  (onButtonClick)="onButtonClick($event)"
></ra-ui-empty-state>
<div class="error-content">
  <span class="second-span-text">
    <ng-content></ng-content>
  </span>
</div>
