// https://github.com/ngrx/platform/issues/931
// https://github.com/brandonroberts/effects-issue-example/blob/master/src/app/utils.ts
import { APP_BOOTSTRAP_LISTENER, InjectionToken, Inject, Type } from '@angular/core';
import { EffectSources } from '@ngrx/effects';
import { ENV_VARS } from './models/config.model';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effects: Type<any>[], sources$: EffectSources) {
  return (): void => {
    effects.forEach((effect) => sources$.addEffects(effect));
  };
}

export function createInstances(...instances: any[]): any[] {
  return instances;
}

export function provideBootstrapEffects(effects: Type<any>[]): any[] {
  return [
    effects,
    { deps: effects, provide: BOOTSTRAP_EFFECTS, useFactory: createInstances },
    {
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
      multi: true,
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: bootstrapEffects,
    },
  ];
}

export function getEnv(key: ENV_VARS): any {
  if (!(key in window) || typeof window[key] === 'undefined') {
    throw new Error(`Env var not defined ${key}`);
  }
  const value = window[key];
  if (typeof value === 'string' && value === '') {
    throw new Error(`Env var is defined ${key} with value ''`);
  }

  return window[key];
}

export function getEnvOrDefault(key: ENV_VARS, defaultVal): any {
  try {
    return getEnv(key);
  } catch (e) {
    return defaultVal;
  }
}
