import { Inject, InjectionToken, Type } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, FinishAppInitializer, isBaseURLSet } from '@ra-state';
import { LoggerService } from '@servicesV2/logger.service';
import { filter, take } from 'rxjs';

export const USER_PROVIDED_EFFECTS = new InjectionToken<Type<any>[]>('@ngrx/effects User Provided Effects');

export function initApplication(store$: Store<AppState>, logger: LoggerService): Function {
  return (): Promise<any> => {
    return new Promise((resolve) => {
      store$
        .select(isBaseURLSet)
        .pipe(
          filter((url) => url === true),
          take(1)
        )
        .subscribe(() => {
          logger.log('RA-inc library load completed');
          store$.dispatch(FinishAppInitializer());
          resolve(true);
        });
    });
  };
}

export const injector = [[new Inject(Store)]];
