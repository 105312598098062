import { Component, ViewChild } from '@angular/core';
import { IToastConfig, ToastDirective, OverlayPosition, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { tap } from 'rxjs';
import { SnackBarFacade } from '@ra-state';
import { Router } from '@angular/router';
@Component({
  selector: 'app-snackbar',
  styleUrls: ['./snackbar.component.scss'],
  templateUrl: './snackbar.component.html',
})
export class SnackBarComponent {
  navigateTo: string;
  constructor(
    private snackbarFacade: SnackBarFacade,
    public router: Router,
  ) {}
  @ViewChild(ToastDirective, { static: true }) public toastRef: ToastDirective;
  public toastConfig: IToastConfig = {
    delay: 7000,
    position: OverlayPosition['TopRight'],
  };
  displayMessage$ = this.snackbarFacade.displayMessage$.pipe(
    tap((data) => {
      const title = data?.title ? data.title : '';
      const type = data?.type ? data?.type : 'Success';
      if (data?.customBtn) {
        this.navigateTo = data.customBtn.navigateTo;
        this.toastRef.buttons = [{ label: `${data.customBtn.label}` }];
        this.toastRef.addToast(data?.message, NotificationType.Success, title);
      } else {
        this.toastRef.addToast(String(data?.message), NotificationType[type], title);
      }
    }),
  );

  btnClick(): void {
    this.router.navigate([this.navigateTo]);
  }
}
