import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, SnackBarData } from '../lemans-app.model';

import { displayMessage } from './snackbar.action';
import { selectDisplayMessage } from './snackbar.selectors';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnackBarFacade {
  constructor(private store$: Store<AppState>) {}
  displayMessage$ = this.store$.select(selectDisplayMessage).pipe(filter((message) => message !== undefined));
  displayMessage(messageData: SnackBarData): void {
    this.store$.dispatch(displayMessage({ payload: messageData }));
  }
}
