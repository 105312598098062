import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShellComponent } from './shell.component';
import { RouterModule } from '@angular/router';
import { DemoMaterialModule } from '@app/material.module';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { NavigationComponent } from './navigation/navigation.component';
import { BreadcrumbModule } from 'xng-breadcrumb';

@NgModule({
  declarations: [ShellComponent, NavigationComponent],
  imports: [CommonModule, RouterModule, DemoMaterialModule, LayoutModule, BreadcrumbModule],
})
export class ShellModule {}
