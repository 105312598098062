import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-content-modal',
  styleUrls: ['./content-modal.component.scss'],
  templateUrl: './content-modal.component.html',
})
export class ContentModalComponent implements OnInit {
  @Input() buttonNames;
  @Input() size = 'medium';
  @Input() contentOnly = false;
  @Output() buttonAction = new EventEmitter();
  classConfig = {};

  btnAction(action: string): void {
    this.buttonAction.emit(action);
  }

  ngOnInit(): void {
    this.classConfig = {
      large: this.size === 'large',
      medium: this.size === 'medium',
      'only-content': this.contentOnly,
      small: this.size === 'small',
    };
  }
}
