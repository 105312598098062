import { createAction, props } from '@ngrx/store';
import { IDomainMessage } from '@ra-state';
import { HubConnectionState } from '@microsoft/signalr';

export const hubProxyInitialized = createAction(
  '[SignalR] Hub Proxy Initialized',
  props<{ payload: HubConnectionState }>()
);
export const updateConnectionState = createAction(
  '[SignalR] Update Connection Status',
  props<{ payload: HubConnectionState; error?: any }>()
);

export const disconnect = createAction('[SignalR] Stop SignalR connection');

export const setDomainMessageReceived = createAction(
  '[SignalR] Domain message received',
  props<{ payload: IDomainMessage }>()
);
