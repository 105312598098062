import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorLoginComponent } from './error-login.component';
import { LayoutModule } from '@rockwell-automation-inc/layout';

@NgModule({
  declarations: [ErrorLoginComponent],
  imports: [CommonModule, LayoutModule],
})
export class ErrorLoginModule {}
