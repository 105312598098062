<ng-container *ngIf="isAuthenticated$ | async; else userNotLoggedIn"></ng-container>

<ng-template #userNotLoggedIn>
  <lib-landing
    [displaySSOSignInButton]="(enableSSOLogin$ | async) || false"
    (signInClick)="login()"
    (ssoSignInClick)="loginSSO()"
    [landingImageUrl]="landingImageURL"
  ></lib-landing>
  <ng-container *ngIf="isAllowTestUsers$ | async"></ng-container>
</ng-template>
