import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: './ag-custom-no-rows-overlay.component.html',
})
export class AgCustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  public params: any = {
    title: '',
    content: '',
  };

  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }
}
