import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectGetCurrentRouteState } from '@ra-state';

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  queryParams$ = this.store$.select(selectGetCurrentRouteState);

  constructor(private store$: Store) {}
}
