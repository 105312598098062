import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { UserFeedback } from '@ra-state';

@Component({
  selector: 'app-feedback.modal',
  styleUrls: ['./feedback.component.scss'],
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements AfterViewInit {
  feedbackOptions = [
    {
      id: 1,
      type: 'Provide Feedback',
    },
    {
      id: 2,
      type: 'Enhancement Request',
    },
    {
      id: 3,
      type: 'Report an Issue',
    },
  ];

  @ViewChild('feedbackForm') feedbackForm: NgForm;
  @ViewChild('fdType') feedbackType: MatSelect;

  constructor(private dialogRef: MatDialogRef<FeedbackComponent>) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.feedbackType.open();
    }, 100);
  }

  cancel(): void {
    this.close(null);
  }

  close(value): void {
    this.dialogRef.close(value);
  }

  sendFeedback(): void {
    const userFeedback: UserFeedback = {
      feedback: this.feedbackForm.value.feedbackText,
      feedbackType: this.feedbackForm.value.feedbackType,
    };
    this.close(userFeedback);
  }
  getInputHint(inputLength, validLength): string {
    return inputLength?.length + '/' + validLength;
  }
}
