export interface SelectionTrigger {
  selector: string;
  value: any[];
  dependentFormControlName: string;
}

// TOOD: these two types need to be reviewed and maybe candidates for removal.
export interface ResourceValue {
  resourceId: string;
  displayResourceName: string;
}
export interface Resources {
  value: ResourceValue;
  viewValue: string;
}

export class AccessMgmtUtils {
  public static resourceType = [
    { value: 'Tenant', viewValue: 'Organization' },
    { value: 'Service', viewValue: 'Service' },
  ];
  public static role = [
    { value: 'Admin', viewValue: 'Administrator' },
    { value: 'Contributor', viewValue: 'Contributor' },
  ];
  public static unsavedMessage = 'Changes will not be saved. Do you want to proceed?';
  public static noUserFoundMessage = '*No User Found';
  public static resourceTrigger = [
    { dependentFormControlName: 'resourceName', selector: 'Tenant', value: [] },
    { dependentFormControlName: 'resourceName', selector: 'Service', value: [] },
  ];
}
