<div class="ag-remove-invitation-container">
  <i
    (click)="iconAction(AG_RENDER_ACTIONS.PRIMARY)"
    class="ra-icon-ide-md-delete"
    role="img"
    aria-hidden="false"
    aria-label="Delete row"
    data-mat-icon-type="font"
    [raUiTooltip]="params?.tooltip"
    [tooltipConfig]="params?.tooltipConfig"
  ></i>
</div>
