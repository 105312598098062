import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import {
  AccessMgmtUtils,
  Resources,
  ResourceValue,
  SelectionTrigger,
} from '@app/v2/access-management/access-management.utils';
import { AgSwitchButtonComponent } from '@app/v2/access-management/ag-switch-button/ag-switch-button.component';
import { AddResourceAccess, UserRole } from '@ra-state';
import { getDisplayResourceType, isRemoveAccessResource } from '../utils';
import { AgCustomNoRowsOverlayComponent } from '@agrenderers/ag-custom-no-rows-overlay/ag-custom-no-rows-overlay.component';
import { environment } from '@environments/environment';
import { GridOptions } from '@ag-grid-community/core';
import { AgRemoveAccessButtonComponent } from '@app/v2/access-management/ag-remove-access-button/ag-remove-access-button.component';
import { TooltipPosition } from '@ra-web-tech-ui-toolkit/components';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-resource-roles-mapper',
  styleUrls: ['./resource-roles-mapper.component.scss'],
  templateUrl: './resource-roles-mapper.component.html',
})
export class ResourceRolesMapperComponent implements OnInit {
  @ViewChild('myForm') myForm: NgForm;
  inputResources: any;
  @Input() set resourcesToMap(resourcesToMap: any) {
    this.mapDropdowns(resourcesToMap);
  }
  @Input() set selectedUser(userData: any) {
    this.userData = userData;
  }
  @Input() disabledActionButton = true;
  @Input() isApproveUserFlow = false;
  @Output() gridReady = new EventEmitter();
  @Output() addResourceRole = new EventEmitter();
  @Output() buttonAction = new EventEmitter();
  @Output() removebuttonAction = new EventEmitter();
  @Output() undoRemovebuttonAction = new EventEmitter();
  resources: Resources[] = [];
  resourcesServices: Resources[] = [];
  gridOptions: GridOptions;
  rowData: AddResourceAccess[];
  addAccess: UntypedFormGroup;
  userData?: UserRole;
  selectorData = {
    resource: [],
    resourceType: AccessMgmtUtils.resourceType,
    role: AccessMgmtUtils.role,
  };
  resourceDataTrigger: SelectionTrigger[] = AccessMgmtUtils.resourceTrigger;
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initializeAGGrid();
  }

  /**
   *
   * Dropdowns
   */
  mapDropdowns(data: any): void {
    this.inputResources = data;
    this.resourcesServices = [];
    this.resources = [];
    for (const key in data) {
      if (data) {
        const resourceData = data[key];
        const displayName = environment.appConfiguration.appName(resourceData.resourceName);
        const resourceValue: ResourceValue = {
          displayResourceName: displayName,
          resourceId: resourceData.resourceId,
        };
        const res = {
          value: resourceValue,
          viewValue: displayName,
        };
        if (resourceData.resourceType === 'Tenant') {
          this.resources.push(res);
        } else if (resourceData.resourceType === 'Service') {
          this.resourcesServices.push(res);
        }
      }
    }
    this.resourceDataTrigger[0].value = this.resources;
    this.resourceDataTrigger[1].value = this.resourcesServices;
  }

  setActivateFormController(): void {
    this.addAccess = this.fb.group({
      resourceName: ['', Validators.required],
      resourceType: ['', Validators.required],
      role: ['', Validators.required],
    });
  }

  /**
   * Grid
   */

  displayResourceType(params): string {
    return getDisplayResourceType(params.value);
  }
  displayResourceName(params): string {
    return environment.appConfiguration.appName(params.value);
  }
  removeAccessClickHandler(action: string, data: any): void {
    if (action === 'remove') {
      this.removebuttonAction.emit(data);
    } else if (action === 'undoRemoveAccess') {
      this.undoRemovebuttonAction.emit(data);
    }
  }
  initializeAGGrid(): void {
    const context = { parent: this };
    const columnDefs = [
      { field: 'resourceType', headerName: 'Resource Type', minWidth: 337, valueFormatter: this.displayResourceType },
      { field: 'resourceName', headerName: 'Resource', minWidth: 350, valueFormatter: this.displayResourceName },
      {
        cellRenderer: 'agSwitchButtonComponent2',
        field: 'role',
        headerName: 'Role',
        minWidth: 400,
        sortable: false,
      },
      // doesn't seems useful?
      // {
      //   field: 'cancel',
      //   getQuickFilterText: (): string => '',
      //   headerName: '',
      //   sortable: false,
      // },
      {
        cellRenderer: 'removeAccessValueButton',
        cellRendererParams: {
          clicked: this.removeAccessClickHandler.bind(this),
          showEditbtn: false,
          isApproveUserFlow: this.isApproveUserFlow,
          deleteTooltip: 'This user account will be denied to access this resource.',
          tooltipConfig: { position: TooltipPosition.Bottom },
        },
        field: 'removeAccess',
        getQuickFilterText: (): string => '',
        headerName: '',
        sortable: false,
      },
    ];
    const defaultColDef = {
      filter: false,
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      unSortIcon: true,
    };
    const components = {
      agSwitchButtonComponent2: AgSwitchButtonComponent,
      removeAccessValueButton: AgRemoveAccessButtonComponent,
    };
    const loadingTemplate = '<span class="ag-overlay-loading-center">Data is loading...</span>';
    const noRowsOverlayComponentParams: any = {
      title: 'No Records Added!',
      content: 'Please try searching with another keyword',
    };
    const getRowHeight = (): number => 56;
    this.gridOptions = {
      headerHeight: 56,
      defaultColDef: defaultColDef,
      columnDefs: columnDefs,
      context: context,
      domLayout: 'autoHeight',
      components: components,
      overlayLoadingTemplate: loadingTemplate,
      noRowsOverlayComponent: AgCustomNoRowsOverlayComponent,
      noRowsOverlayComponentParams: noRowsOverlayComponentParams,
      getRowHeight: getRowHeight,
      getRowClass: (params): string => {
        return isRemoveAccessResource(params?.data) ? 'row-to-delete' : '';
      },
    };
    // create form group of controls
    this.setActivateFormController();
  }

  onSubmit(): void {
    if (this.addAccess.valid) {
      const data: any = [];
      this.addAccess.value['resourceName'].forEach((res) => {
        const inputRes = this.inputResources[res.resourceId];
        data.push({
          resourceId: inputRes.resourceId,
          resourceName: inputRes.resourceName,
          resourceType: inputRes.resourceType,
          role: this.addAccess.value['role'],
          userId: this.userData?.userId,
          userName: this.userData?.userName,
        });
      });
      this.addResourceRole.emit(data);
      this.addAccess.reset();
      this.myForm.resetForm();
    }
  }

  onGridReady(params): void {
    this.gridReady.emit(params);
  }

  btnAction(action: number): void {
    this.buttonAction.emit({
      btnAction: action,
      touched: this.addAccess.touched,
    });
  }
}
