/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { FetaureFlags } from '../lemans-app.model';
import { deleteFeatureFlag, setFeatureFlags, updateFeatureFlag } from './feature-flags.actions';

export const featureFlagsInitialState: FetaureFlags = {};

const featureFlagsActions = createReducer(
  featureFlagsInitialState,
  on(setFeatureFlags, (state: FetaureFlags, { flags }) => {
    return { ...state, flags: flags };
  }),
  on(updateFeatureFlag, (state: FetaureFlags, { payload: harnessUpdate }) => {
    const flags = _.clone(state.flags);
    if (flags) {
      flags[harnessUpdate.flag] = harnessUpdate.value;
    }
    return { ...state, flags: flags };
  }),
  on(deleteFeatureFlag, (state: FetaureFlags, { payload: flag }) => {
    const flags = _.clone(state.flags);
    if (flags) {
      delete flags[flag];
    }
    return { ...state, flags: flags };
  })
);

export function featureFlagsReducer(state: FetaureFlags, action: Action): FetaureFlags {
  return featureFlagsActions(state, action);
}
