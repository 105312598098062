/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private ctx = '';

  isProduction = environment.production;
  writeLog(logfn: Function, msg: any, ...optionalParams: any[]): void {
    if (this.isProduction) {
      return;
    }
    if (this.ctx === '') {
      logfn.call(this, ...[msg, ...optionalParams]);
    } else {
      logfn.call(this, ...[`[${this.ctx}]`, msg, ...optionalParams]);
    }
  }
  log(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.info, msg, ...optionalParams);
  }

  error(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.error, msg, ...optionalParams);
  }

  warn(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.warn, msg, ...optionalParams);
  }
  debug(msg: any, ...optionalParams: any[]): void {
    this.writeLog(console.debug, msg, ...optionalParams);
  }
  withContext(childCtx: string): LoggerService {
    const logger = new LoggerService();
    logger.ctx = this.ctx === '' ? childCtx : `${this.ctx}.${childCtx}`;
    return logger;
  }
}
