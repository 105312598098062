import { Component, OnInit, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { filter, map, Subject, Subscription, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlPageFacade, SignalRFacade, SnackBarFacade, UserDataFacade } from '@ra-state';
import { NgScrollbar } from 'ngx-scrollbar';
import { IDialogConfig, DialogService, NotificationType, ActionButtonStyles } from '@ra-web-tech-ui-toolkit/components';
import { concatLatestFrom } from '@ngrx/effects';
import { NavBarTheme } from '@rockwell-automation-inc/common-utils';
import { AuthenticationService } from '../auth/auth.service';

@Component({
  selector: 'app-eula',
  styleUrls: ['./eula.component.scss'],
  templateUrl: './eula.component.html',
})
export class EulaComponent implements OnInit, AfterViewInit {
  navbarTheme = NavBarTheme.DARK;
  url: any;
  isScrolledToBottom$ = new Subject();
  scrollSubscription = Subscription.EMPTY;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  eulaContent$ = this.controlPageFacade.eulaContent$.pipe(
    concatLatestFrom(() => [this.controlPageFacade.isEulaAccepted$]),
    map(([content, isEulaAccepted]) => {
      if (content && !isEulaAccepted) {
        this.snackBarFacade.displayMessage({
          //icon: MessageIcons.LicenseScroll, // Alternate icon
          message: "Scroll to enable 'Accept' button",
          type: 'Info',
        });
      }
      return content;
    }),
  );

  isEulaAccepted$ = this.controlPageFacade.isEulaAccepted$;

  onHubConnected$ = this.signalRFacade.isHubConnected$;

  redirectTo: string | undefined;

  ngOnInit(): void {
    this.route.queryParams.pipe(filter((params) => params.redirectTo)).subscribe((params) => {
      this.redirectTo = params.redirectTo;
    });
    this.controlPageFacade.getEulaConfig();
  }

  ngAfterViewInit(): void {
    this.scrollSubscription = this.scrollbarRef.verticalScrolled
      .pipe(
        map((event: any) => event.target.scrollHeight - (event.target.offsetHeight + event.target.scrollTop) <= 10),
        tap((isScrolledToBottom: boolean) => this.ngZone.run(() => this.isScrolledToBottom$.next(isScrolledToBottom))),
      )
      .subscribe();
  }

  acceptEULA(): void {
    // this must dispatch an action and handle all api calls in the effects
    // go to angular-app\src\app\+state\control-page.effects.ts
    this.controlPageFacade.acceptEULA(this.redirectTo);
  }

  declineEULA(): void {
    const config: IDialogConfig = {
      title: 'Decline License Agreement',
      message: "By selecting 'decline', you will be signed-out of the application.",
      messageType: NotificationType.Warning,
      buttons: [
        {
          label: 'Decline',
          buttonStyle: ActionButtonStyles.Main,
          shouldAutofocus: true,
        },
        {
          label: 'Cancel',
        },
      ],
    };

    const dialogRef = this.dialogSvc.openDialog(config);
    dialogRef.componentInstance.onClick.subscribe((event: any) => {
      if (event.label === 'Decline') {
        this.authService.logout();
      }
    });
  }

  goToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  logoutClicked(): void {
    this.authService.logout();
  }

  constructor(
    private router: Router,
    private dialogSvc: DialogService,
    public authService: AuthenticationService,
    private controlPageFacade: ControlPageFacade,
    private userDataFacade: UserDataFacade,
    private snackBarFacade: SnackBarFacade,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private signalRFacade: SignalRFacade,
  ) {}

  get logoTypeThemeClass(): string {
    return this.navbarTheme === NavBarTheme.DARK ? 'ra-logo-ft-hub-dark' : 'ra-logo-ft-hub-light';
  }
}
