import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ControlPageFacade, SnackBarFacade, UserDataFacade } from '@ra-state';
import { Location } from '@angular/common';

@Component({
  selector: 'app-build-information',
  styleUrls: ['./build-information.component.scss'],
  templateUrl: './build-information.component.html',
})
export class BuildInformationComponent {
  @ViewChild('versionInfo', { read: ElementRef }) versionInfo: ElementRef<HTMLElement>;
  versionInfos$ = this.controlPageFacade.versionInfos$;
  errorContext$ = this.controlPageFacade.errorContext$;
  @Input() showtitle = true;
  @Input() showBackButton = true;
  @Input() showError = false;

  constructor(
    private controlPageFacade: ControlPageFacade,
    public userDataFacade: UserDataFacade,
    private clipboard: Clipboard,
    private location: Location,
    private snackBar: SnackBarFacade
  ) {}

  cdkCopyToClipboard(): void {
    this.clipboard.copy(String(this.versionInfo.nativeElement.innerText));
    this.snackBar.displayMessage({
      type: 'Success',
      message: 'Copied to clipboard',
    });
  }

  back(): void {
    this.location.back();
  }
}
