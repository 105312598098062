import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, IGainsightCustomEvent } from '../lemans-app.model';
import { resetSession, pushCustomEvent } from './gainsight.actions';

@Injectable({
  providedIn: 'root',
})
export class GainsightFacade {
  constructor(private store$: Store<AppState>) {}

  resetSession(): void {
    this.store$.dispatch(resetSession());
  }

  pushCustomEvent(gainsightCustomEvent: IGainsightCustomEvent): void {
    this.store$.dispatch(pushCustomEvent({ gainsightCustomEvent }));
  }
}
