import { createAction, props } from '@ngrx/store';
import { HarnessIdentifier, FeatureFlag, FeatureFlagTarget, FeatureFlagUpdate, FlagValue } from '@ra-state';

export const intializeTarget = createAction(
  '[Feature-Flag] Initialize Target',
  props<{ payload: FeatureFlagTarget }>()
);

export const closeTarget = createAction(
  '[Close-Target] Close Target',
  props<{ harnessIdentifier: HarnessIdentifier }>()
);

export const setFeatureFlags = createAction(
  '[Feature-Flag] Set Feature Flags',
  props<{ flags: Record<string, FlagValue> }>()
);

export const updateFeatureFlag = createAction(
  '[Feature-Flag] Update Feature Flag',
  props<{
    payload: FeatureFlagUpdate;
  }>()
);

export const deleteFeatureFlag = createAction('[Feature-Flag] Delete Feature Flag', props<{ payload: FeatureFlag }>());

export const emptyHarnessAction = createAction('[EMTPY] Empty Action');
