import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { displayMessage } from './snackbar.action';

@Injectable({
  providedIn: 'root',
})
export class SnackBarEffects {
  displayMessage$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(displayMessage),
        map((payload) => {
          return displayMessage({ payload: payload.payload });
        })
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
