import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';
import { EnsureModuleLoadedOnceGuard } from '../guards/ensure-module-loaded-once.guard';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SharedModule } from '@shared/shared.module';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { CatalogDialogComponent } from './catalog-dialog/catalog-dialog/catalog-dialog.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ReactiveFormsModule } from '@angular/forms';
import { RaUiButtonModule, RaUiInputModule } from '@ra-web-tech-ui-toolkit/components';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [ModalComponent, WelcomeDialogComponent, CatalogDialogComponent],
  exports: [ModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    ReactiveFormsModule,
    RaUiButtonModule,
    RaUiInputModule,
  ],
  providers: [ModalService],
})
export class ModalModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that ModalModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: ModalModule) {
    super(parentModule);
  }
}
