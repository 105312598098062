<section class="ag-switch-btn-container">
  <div *ngIf="isOwnerRole">
    <span> Owner </span>
  </div>

  <section *ngIf="!isOwnerRole" class="btn-toggle-container">
    <mat-button-toggle-group [formControl]="fontStyleControl" name="fontStyle" aria-label="Font Style">
      <mat-button-toggle
        id="Administrator"
        value="Admin"
        (change)="onValChange($event.value)"
        class="btn-toggle"
        [ngClass]="{ 'btn-enabled': cellValue === 'Admin' }"
        >Administrator</mat-button-toggle
      >

      <mat-button-toggle
        id="Contributor"
        value="Contributor"
        (change)="onValChange($event.value)"
        class="btn-toggle"
        [ngClass]="{ 'btn-enabled': cellValue === 'Contributor' }"
        >Contributor</mat-button-toggle
      >
    </mat-button-toggle-group>
  </section>
</section>
