import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SnackBarComponent } from './snackbar/snackbar.component';
import { DemoMaterialModule } from '@app/material.module';
import { RaUiToastModule } from '@ra-web-tech-ui-toolkit/components';

@NgModule({
  declarations: [SnackBarComponent],
  exports: [SnackBarComponent, RaUiToastModule],
  imports: [CommonModule, DemoMaterialModule, RaUiToastModule, RouterModule],
  providers: [],
})
export class SnackbarModule {}
