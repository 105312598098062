import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { AuthenticationService } from '../auth.service';
import { FeatureFlagsFacade } from '@ra-state';
import { Connections } from '@app/models/config.model';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-landing',
  styleUrls: ['./landing.component.scss'],
  templateUrl: './landing.component.html',
})
export class LandingComponent {
  returnTo: string;
  allowTestUsers: boolean;
  landingImageURL: string = `${environment.appConfiguration.csPortalUrl}/assets/images/LeMans-Cloud-Services.jpg`;
  isAllowTestUsers$ = this.featureFlagsFacade.getFlagValue$('allow_test_users').pipe(
    filter((allowTestUsers) => allowTestUsers !== undefined),
    tap((allowTestUsers) => {
      this.allowTestUsers = Boolean(allowTestUsers);
    }),
  );

  enableSSOLogin$ = this.featureFlagsFacade.getFlagValue$('enable_sso_login').pipe(
    filter((enableSSOLogin) => enableSSOLogin !== undefined),
    map((enableSSOLogin) => {
      return Boolean(enableSSOLogin);
    }),
  );

  isAuthenticated$ = this.authenticationService.isAuthenticated$.pipe(
    withLatestFrom(this.route.queryParams),
    tap(([isAuthenticated, params]) => {
      this.returnTo = this.getReturnTo(params);
      if (isAuthenticated) {
        this.router.navigateByUrl(this.returnTo);
      }
    }),
    map(([isAuthenticated, _params]) => isAuthenticated),
  );

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private featureFlagsFacade: FeatureFlagsFacade,
    private router: Router,
  ) {}

  getReturnTo(params: Params): string {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      if (key !== 'returnTo') {
        searchParams.set(key, params[key]);
      }
    }
    const searchParamsStr = searchParams.toString();
    let returnTo = `${params.returnTo}`;
    if (searchParamsStr) {
      returnTo = returnTo.concat(`?${searchParams.toString()}`);
    }
    return returnTo;
  }

  login(): void {
    this.authenticationService.login(this.returnTo, this.allowTestUsers);
  }

  loginSSO(): void {
    this.authenticationService.loginSSO(Connections.Rockwell, this.returnTo);
  }
}
