<section class="dropdown-mat-container" id="{{ controlName }}">
  <mat-form-field appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      id="{{ controlName }}_select"
      [formControl]="control"
      multiple="{{ multiple }}"
      (selectionChange)="selection($event.value)"
    >
      <mat-option class="options" *ngFor="let item of Data" [value]="item.value">{{ item.viewValue }}</mat-option>
    </mat-select>
    <ra-ui-error-message
      *ngIf="control && control.touched && control.invalid"
      [message]="controlName + ' is required'"
    ></ra-ui-error-message>
  </mat-form-field>
</section>
