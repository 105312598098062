import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, Subject, merge } from 'rxjs';
import { tap, concatMap, switchMap, mergeMap } from 'rxjs/operators';
import { disconnect, setDomainMessageReceived, hubProxyInitialized, updateConnectionState } from './signalr.actions';
import { IDomainMessage, isDomainMessage } from '../lemans-app.model';

import { SignalrService, HubConnectionProxy, HubStatus } from '@rockwell-automation-inc/service';
import { LoggerService } from '@servicesV2/logger.service';
import { HubConnectionState } from '@microsoft/signalr';
import { Action } from '@ngrx/store';
import { userLoginComplete } from '../control-page';
@Injectable({
  providedIn: 'root',
})
export class SignalREffects {
  // needs to be instance variable that is set at the end of from the signalR service connection state observer
  hubProxy: HubConnectionProxy;

  domainMsgSubscriber$ = new Subject<IDomainMessage>();
  connectionStateSubscriber$ = new Subject<HubConnectionState>();
  onDomainMessage$ = this.domainMsgSubscriber$.asObservable();
  onConnectionStateUpdate$ = this.connectionStateSubscriber$.asObservable();
  eventListenersHookedup = false;

  setStartConnection$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      tap(() => {
        this.logger.log('starting connection');
        this.signalRService.startConnection();
      }),
      concatMap(() => this.signalRService.connectionStatus$),
      //TODO: stinks... simple map doesn't work as it wants an obs
      switchMap((proxy: HubStatus) => {
        if ('error' in proxy) {
          return [updateConnectionState({ payload: HubConnectionState.Disconnected, error: proxy.error })];
        } else {
          this.logger.log('set hub proxy');
          if ('error' in proxy) {
            return [updateConnectionState({ payload: HubConnectionState.Disconnected, error: proxy.error })];
          } else {
            this.hubProxy = proxy;
            return [hubProxyInitialized({ payload: proxy.HubConnection.state })];
          }
        }
      })
    );
  });

  setHubConnected$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(hubProxyInitialized),
      tap(() => {
        if (!this.eventListenersHookedup) {
          this.hubProxy.on('OnDomainMessageReceived', (domainMsg) => this.domainMsgSubscriber$.next(domainMsg));
          this.hubProxy.onreconnected(() => this.connectionStateSubscriber$.next(this.hubProxy.HubConnection.state));
          this.hubProxy.onreconnecting(() => this.connectionStateSubscriber$.next(this.hubProxy.HubConnection.state));
          this.hubProxy.onclose((err?: Error) => {
            // we should handle disconncts
            //https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#automatically-reconnect
            // TODO: need to thread the error back to the store
            // TODO: need to thread the error back to the store
            if (err) {
              this.logger.error(err);
            }
            this.connectionStateSubscriber$.next(this.hubProxy.HubConnection.state);
          });
          this.logger.log('Hooking up signalr event listeners to observables');
          this.eventListenersHookedup = true;
        }
      }),
      switchMap(() => merge(this.onDomainMessage$, this.onConnectionStateUpdate$)),
      mergeMap((msg) => {
        if (isDomainMessage(msg)) {
          return [setDomainMessageReceived({ payload: msg })];
        } else {
          //prevent loop by raising an update event
          this.logger.log('Publishing connection state update event', msg);
          return [updateConnectionState({ payload: msg })];
        }
      })
    );
  });

  disconnect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(disconnect),
      tap(() => {
        this.logger.log('closing connection');
        this.signalRService.stopConnection();
      })
    );
  });

  constructor(private actions$: Actions, private signalRService: SignalrService, private logger: LoggerService) {
    this.logger = logger.withContext('SignalrEffects');
  }
}
