/* eslint-disable sort-keys */
import { Injectable } from '@angular/core';
import { ConfigService, CoreConfiguration } from '@rockwell-automation-inc/service';
import { LoggerService } from './logger.service';
import {
  CommandRequest,
  DomainEventName,
  DomainEvents,
  EventPredicate,
  IDomainUpdateRequestBuilderOptions,
} from './command-request.service';

@Injectable({
  providedIn: 'root',
})
export class CommandRequestBuilderService {
  constructor(private configService: ConfigService<CoreConfiguration>, private logger: LoggerService) {}

  // TO DO: define retuning type
  new(
    apiPath: string,
    method: 'POST' | 'DELETE' | 'PUT',
    domainEvent: EventPredicate | DomainEventName | DomainEvents,
    options: IDomainUpdateRequestBuilderOptions = {} as IDomainUpdateRequestBuilderOptions
  ): CommandRequest {
    const apiEndPoint = this.configService.config.csApiBaseUrl + apiPath;
    this.logger.log('creating CommandRequestService instance for:  ', apiEndPoint);
    return CommandRequest.create(apiEndPoint, method, domainEvent, options);
  }
}
