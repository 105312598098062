import { Action, createReducer, on } from '@ngrx/store';
import { SignalRData } from '../lemans-app.model';
import { disconnect, setDomainMessageReceived, hubProxyInitialized, updateConnectionState } from './signalr.actions';

export const signalRInitialState: SignalRData = {};

const setSignalRData = createReducer(
  signalRInitialState,
  on(
    hubProxyInitialized,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      connectionState: payload,
    })
  ),
  on(
    updateConnectionState,
    (state: SignalRData, { payload, error }): SignalRData => ({
      ...state,
      connectionState: payload,
      lastError: error,
    })
  ),
  on(
    setDomainMessageReceived,
    (state: SignalRData, { payload }): SignalRData => ({
      ...state,
      domainMessage: payload,
    })
  ),
  on(disconnect, (state: SignalRData): SignalRData => {
    const newState = { ...state };
    delete newState.connectionState;
    return newState;
  })
);

export function signalrReducer(state: SignalRData, action: Action): SignalRData {
  return setSignalRData(state, action);
}
