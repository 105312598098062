<mat-dialog-content class="modal-container">
  <h2 class="modal-heading">{{ data.header }}</h2>
  <div class="modal-body" [innerHTML]="data.body"></div>
</mat-dialog-content>
<mat-dialog-actions class="modal-actions">
  <ra-ui-main-button label="{{ data.OKButtonText }}" class="space-12" (click)="ok()"> </ra-ui-main-button>
  <ra-ui-outlined-button
    *ngIf="data.cancelButtonVisible"
    label="{{ data.cancelButtonText }}"
    (click)="cancel()"
  ></ra-ui-outlined-button>
</mat-dialog-actions>
