<ng-content
  *ngIf="
    (userID$ | async) &&
    (currentTenantId$ | async) &&
    (effectiveRoles$ | async) &&
    (availableLinks$ | async) &&
    (signalRConnected$ | async)
  "
>
</ng-content>

<!-- TODO: The  notification$ input will be removed -->
<!-- TODO: Refactor supportedLanguages input -->
<lib-common-mat-navbar
  class="app-navbar-container"
  [displaySlideToggleTheme]="enableThemeSwitcher$ | async"
  [initialTheme]="navbarTheme"
  [logotypeClass]="logoTypeThemeClass"
  [isAuthenticated]="(authService.isAuthenticated$ | async)!"
  [organizations]="(orgs$ | async)!"
  [currentOranizationName]="(getCurrentTenantName$ | async)!"
  [currentOrganizationLogo]="(getCurrentTenantLogo$ | async)!"
  [showOrganizationSwitcher]="showOrganizationSwitcher$ | async"
  [accountMenu]="getAccountMenuData$ | async"
  [unreadNotificationsCount]="(getUnreadNotificationsCount$ | async)!"
  [totalNotificationsCount]="getTotalNotificationsCount$ | async"
  [notificationsInProgress]="(notificationsInProgress$ | async) || []"
  [notifications]="(getNotifications$ | async)!"
  [notificationService$]="notificationService$"
  [displayChangeOrganization]="(hasOnlyPersonalTenant$ | async) !== true"
  [displayOnlyLogo]="(currentCustomProvisioningData$ | async) !== undefined"
  [enableUserActionsSideNav]="true"
  [enableNotificationsSideNav]="true"
  [enableHelpSideNav]="true"
  [enableOrganizationSwitcher]="true"
  [helpItems]="helpItems"
  [techSupportURL]="(techSupportURL$ | async)!"
  [navItems]="navItems"
  [bannerItems]="bannerItems"
  [homeMenuTitle]="homeMenuTitle"
  [supportedLanguages]="supportedLanguages"
  [displayLanguageToggle]="displayLanguageToggle$ | async"
  [selectedLang]="selectedLang"
  [helpMenuToOpen]="(helpMenuToOpen$ | async)!"
  [mfaReset]="mfaResetEnable"
  [resetmfa$]="resetmfa$"
  (changeOrganization)="changeOrg($event)"
  (helpItemClick)="helpItemClicked($event)"
  (menuItemClick)="menuItemClicked($event)"
  (logoutClick)="logoutClicked()"
  (goToHomeClick)="goToHome()"
  (raLogoClick)="goToHome()"
  (bannerButtonClick)="bannerButtonClicked($event)"
  (bannerCloseButtonClick)="hideBanner($event)"
  (notificationAction)="notificationAction($event)"
  (currentTheme)="setLogoTheme($event)"
  (langItemClick)="setSelectedLang($event)"
  (resetMFA)="resetMFA()"
>
  <ng-container *ngIf="(userId$ | async) && (initializeNavbar$ | async)"></ng-container>
  <ng-container *ngIf="disableTokenNegativeBalanceNotification$ | async"></ng-container>
  <ng-container *ngIf="authService.isAuthenticated$ | async">
    <ng-container *ngIf="languageChanged$ | async"></ng-container>
    <ng-container *ngIf="preferredLangauge$ | async"></ng-container>
    <ng-container *ngIf="showOrganizationSwitcher$ | async"></ng-container>
    <ng-container *ngIf="maintenanceWindow$ | async"></ng-container>
    <ng-container *ngIf="maintenanceWindowCompleted$ | async"></ng-container>
    <ng-container *ngIf="updateOrganizations$ | async"> </ng-container>
    <ng-container *ngIf="onHubConnected$ | async"> </ng-container>
    <ng-container *ngIf="signalRUserNotifications$ | async"></ng-container>
    <ng-container *ngIf="notificationAction$ | async"></ng-container>
    <ng-container *ngIf="refreshNotificationsGrid$ | async"></ng-container>
    <ng-container *ngIf="helpMenuToOpen$ | async"></ng-container>
    <ng-container *ngIf="sessionTimedOut$ | async"></ng-container>
    <ng-container *ngIf="click$ | async"></ng-container>
    <ng-container *ngIf="countDownTimer$ | async"></ng-container>
  </ng-container>
  <ng-container *ngIf="onEulaAccepted$ | async">
    <ng-container *ngIf="getUserNotificationCounts$ | async"></ng-container>
    <ng-container *ngIf="getUserNotifications$ | async"></ng-container>
  </ng-container>

  <div class="main-container">
    <app-navigation *ngIf="shouldShowBreadcrumb"></app-navigation>
    <ng-container *ngIf="signalRDisconnected$ | async"></ng-container>
    <router-outlet></router-outlet>
  </div>
</lib-common-mat-navbar>
