import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DataService } from '@servicesV2/data.service';
import { HarnessService } from '@servicesV2/harness.service';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';
import { ModalModule } from './modal/modal.module';
import { SnackbarModule } from './snackbar/snackbar.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/components';

@NgModule({
  declarations: [SpinnerComponent],
  exports: [ModalModule, SnackbarModule, SpinnerComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ModalModule,
    SnackbarModule,
    MatProgressSpinnerModule,
    RaUiLoadingSpinnerModule,
  ],
  providers: [DataService, HarnessService],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
