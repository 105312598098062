import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-modal',
  styleUrls: ['./modal.component.scss'],
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      header: string;
      body: string;
      cancelButtonText: string;
      OKButtonText: string;
      cancelButtonVisible: boolean;
    },
    private dialogRef: MatDialogRef<ModalComponent>
  ) {}

  cancel(): void {
    this.close(false);
  }

  close(value): void {
    this.dialogRef.close(value);
  }

  ok(): void {
    this.close(true);
  }
}
