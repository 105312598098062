import * as fromRouter from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { StoreRootState } from './router.reducer';

export const getRouterState = (state: StoreRootState): any => state.router;

export const selectGetCurrentRouteState = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState) => state?.state
);
