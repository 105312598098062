<section
  class="resend-cancel-invitation-container"
  *ngIf="buttonsConfig?.displayAll === AG_RENDER_BUTTONS.BOTH_BUTTONS"
>
  <ra-ui-outlined-button
    label="{{ buttonsConfig.btn1.value }}"
    (click)="buttonAction(AG_RENDER_ACTIONS.PRIMARY)"
    [disabled]="!buttonsConfig.btn1.enable"
  ></ra-ui-outlined-button>
  <ra-ui-outlined-button
    label="{{ buttonsConfig.btn2.value }}"
    (click)="buttonAction(AG_RENDER_ACTIONS.SECONDARY)"
    [disabled]="!buttonsConfig.btn2.enable"
  ></ra-ui-outlined-button>
</section>
<section
  class="resend-cancel-invitation-container"
  *ngIf="buttonsConfig?.displayAll === AG_RENDER_BUTTONS.FIRST_BUTTON"
>
  <ra-ui-outlined-button
    label="{{ buttonsConfig.btn1.value }}"
    (click)="buttonAction(AG_RENDER_ACTIONS.PRIMARY)"
    [disabled]="!buttonsConfig.btn1.enable"
  ></ra-ui-outlined-button>
</section>
<div class="resend-cancel-invitation-container" *ngIf="buttonsConfig?.displayAll === AG_RENDER_BUTTONS.SECOND_BUTTON">
  <ra-ui-outlined-button
    label="{{ buttonsConfig.btn2.value }}"
    (click)="buttonAction(AG_RENDER_ACTIONS.SECONDARY)"
    [disabled]="!buttonsConfig.btn2.enable"
  ></ra-ui-outlined-button>
</div>
