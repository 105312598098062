import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { HubConnectionState } from '@microsoft/signalr';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { AppId, NavItem } from '@rockwell-automation-inc/common-utils';
import { UserProfile } from '@rockwell-automation-inc/service';
import { CommandRequest, DomainEventName } from '@servicesV2/command-request.service';
import { DateTime } from 'luxon';

export interface AppState {
  controlPage: PageControl;
  userData: UserPreferences;
  signalR: SignalRData;
  agGrid: AgGridState;
  featureFlags: FetaureFlags;
  snackBarData: SnackBarMessage;
}

export interface FetaureFlags {
  flags?: Record<string, FlagValue>;
}
export interface Version {
  apiVersion: string;
  portalVersion: string;
  notificationsVersion: string;
}

export interface PageControl {
  versions?: Version;
  isLoading: boolean;
  hasError?: boolean;
  isEulaAccepted: boolean;
  applications: ApplicationCard[];
  enabledApplications: ApplicationCard[];
  isUserNotFound: boolean;
  isBaseURLSet: boolean;
  showOrganizationSwitcher?: boolean;
  eulaConfig?: IEulaConfig;
  eulaContent?: string;
  errorContext?: ErrorContext;
  catalogInfo?: ICatalogInfo[];
  isUserActive: boolean;
  sessionTimedOut: boolean;
  extendSession: boolean;
  logoutSession: boolean;
  invitationId?: string;
  helpMenuToOpen?: NavItem;
  loggedInUserProfile?: UserProfile;
  routeState: RouteState;
}

export const APP_TENANT_MGMT_ROUTES = [
  '/access-mngmt',
  '/entitlement',
  '/invite-users',
  '/approve-users',
  '/invitation-management',
  '/organization/edit',
];

export interface Tenant {
  item1: string;
  item2: string;
}

export interface SignalRData {
  connectionState?: HubConnectionState;
  domainMessage?: IDomainMessage;
  lastError?: any;
}

export enum Role {
  Navigator = 'Navigator',
  Reader = 'Reader',
  Contributor = 'Contributor',
  Admin = 'Admin',
  Owner = 'Owner',
}

// ROLES ACCESSS
export const ROLES_WITH_ENTITLEMENT_ACCESS = [Role.Owner, Role.Admin, Role.Contributor, Role.Navigator];
export const ROLES_WITH_MANAGE_ACCESS = [Role.Owner, Role.Admin];
export const ROLES_WITH_CREDIT_LEDGER_ACCESS = [Role.Owner, Role.Admin];

const UNASSIGNABLE_ROLES = [Role.Navigator, Role.Reader];
export const ASSIGNABLE_ROLES = Object.values(Role).filter((role) => !UNASSIGNABLE_ROLES.includes(role));

export interface UserInfo {
  userId: string;
  userName: string;
  userEmail: string;
  firstName: string;
  lastName: string;
}

export type AddResourceAccess = EffectiveRole & {
  userId: string;
  userName: string;
};
export type RemoveResourceAccess = EffectiveRole & {
  userId: string;
  userName: string;
  availableToDelete: boolean;
};

export type ManageResourceAccess = AddResourceAccess | RemoveResourceAccess;

//TODO: discuss with sagar
export enum ResourceType {
  Tenant = 'Tenant',
  Service = 'Service',
}

export type ResourceTypeString = keyof typeof ResourceType;
export interface Resource {
  resourceId: string;
  resourceName: string;
  resourceType: ResourceTypeString;
}
export type TenantRole = {
  role: Role;
  tenantId: string;
};
export type EffectiveRole = Resource & TenantRole;
export type UserRole = UserInfo &
  Resource &
  TenantRole & {
    id: string;
  };
export interface UserPreferences {
  accessibleTenants?: AccessibleTenants[];
  businessPartnerId: string;
  company: string;
  email: string;
  eulaAcceptanceRequired: boolean;
  eulaContentUrl: string;
  eulaVersion: string;
  firstName: string;
  lastName: string;
  location: string;
  name: string;
  preferences?: Preferences;
  effectiveRoles?: EffectiveRole[];
  resourceRoles?: AccessManagementRecords[];
  userResourceRoles?: AccessManagementRecords[];
  resources?: any;
  editUser?: UserRole;
  userId?: string;
  tenants?: Tenant[];
  currentTenantId?: string;
  newTenantCreated?: boolean;
  currentTenantContext?: ITenantContext;
  currentTenant?: ITenantDetail;
  notificationCounts?: UserNotificationCounts;
  notifications?: UserNotification[];
  notificationDeleted?: UserNotification;
  notificationMarkedRead?: string;
  updatedTenantName?: string;
  servicesVisibility: ServiceVisibility[];
  services: TenantService[];
  tenantUtilityTokens?: TenantUtilityTokens;
  currentCustomProvisioningData?: EntitlementData;
  exitFromProvisioning?: boolean;
  showJoinSuccessInlineMessage?: boolean;
  suggestedTenant?: ITenantDetail;
  trialReservation?: TrialReservation;
  selectedTenantUsers?: PageResponse<TenantUser>;
  noSuggestedTenant?: boolean;
}

export interface TenantUser {
  userId: string;
  firstName: string;
  lastName: string;
  userName: string;
  userEmail?: string;
}

export interface CreateTenant {
  name: string;
  location: string;
  description: string;
  visibility: string;
  trialServices: string[];
}

export interface CreateTenantResponse {
  tenantId: string;
}

export interface ResponseBody<T> {
  body: T;
}

export interface Response<T> {
  response: ResponseBody<T>;
}

export interface UpdateTenant {
  name: string;
  tenantId: string;
  location: string;
  description: string;
  visibility: string;
}

export interface JoinTenant {
  resourceId: string;
  tenantId: string;
}

export interface ApplicationCardStaticConfiguration {
  appDescription: string;
  appHub: string;
  appId: AppId | PreviewAppId;
  appImage: string;
  appLogo: string;
  appLogo2?: string;
  appName: string;
  disabledAppImage: string;
  disabledAppLogo: string;
  hubLogo: string;
  isAppActive: (servicesAccessibility: TenantService[]) => boolean;
  isActive: boolean;
  isDefaultTrialEnabled?: boolean;
  badgeText?: BadgeTextConfig;
  dialogConfig?: {
    instructions: string;
    iconInstructions?: string;
    bannerArtwork: string;
    styleNameToSetDialogPaddingToZero?: string;
    actions?: ActionConfig[];
  };
  beingProvisioned?: boolean;
  provisionServiceId?: string;
  poweredBy?: string;
  isFavorite?: boolean;
  featureFlagName?: FeatureFlag;
  supportsUniversalCredits?: boolean;
}

export enum BadgeTextConfig {
  TestersOnly = 'Testers Only',
  TrialAccess = 'Trial Access',
  PrivatePreview = 'Private Preview',
}

export interface ActionConfig {
  displayName: string;
  icon: string;
  applicationPath: string;
  queryParams: Map<string, string>;
}
export interface ApplicationCard extends ApplicationCardStaticConfiguration {
  appURL: string;
  provisioningUrl?: string;
}

export interface Preferences {
  lastAccessedTenantId?: string;
  disableTokenNotification?: boolean;
  // TODO: Why is this is a union
  favoriteApps?: FavoriteApps;
  language?: string;
}

export interface FavoriteApps {
  // TODO: move to appid?
  [key: string]: (AppId | PreviewAppId)[];
}

export interface AccessibleTenants {
  id: string;
  name: string;
  logoUrl?: string;
}

export type UniversalCredits = 'UniversalCredits';

export enum PreviewAppId {
  Fiix = 'Fiix',
  Plex = 'Plex',
  FactoryTalkMotionAnalyzer = 'FactoryTalkMotionAnalyzer',
  Advisor = 'Advisor',
}

export function isPreviewApp(s: AppId | PreviewAppId | string): s is PreviewAppId {
  if (typeof s === 'string') {
    return PreviewAppId[s] !== undefined;
  }
  return Object.values(PreviewAppId).includes(s);
}

export interface ITenantDetail {
  id: string;
  name: string;
  ownerName: string;
  location: string;
  inviteCode: string;
  description: string;
  emailDomain: string;
  logoUrl: string;
  visibility: string;
  logoImage?: string;
  services?: Service[];
  disabledServices?: DisabledService[];
  pendingServices?: PendingService[];
}

export interface ITenantContext {
  id: string;
  name: string;
  role: Role;
}

export interface ICatalogInfo {
  catalogCode: string;
  name: string;
  description: string;
}
export interface IEulaConfig {
  eulaVersion: string;
  eulaContentUrl: string;
}

export interface IUpdatedPreferences {
  preferences: IPreferenceKeyValue[];
}

export interface IPreferenceKeyValue {
  name: string;
  value: string;
}

// TO DO: verify this interface, doesn't match with response
export interface IApiResponse {
  status: boolean;
  error?: string;
}

export interface CreateInvitation {
  resourceId: string;
  role: Role;
  resourceType: string;
  toEmail: string;
  tenantId: string;
}

export interface UserFeedback {
  feedback: string;
  feedbackType: string;
}

export interface NoContentApiResponse {
  [key: string]: any;
}

export interface NoContent {
  [key: string]: any;
}

export interface KeyValue {
  [key: string]: any;
}

export const NO_CONTENT: NoContent = {};

export interface UserNotification {
  id: string;
  createdOn: Date;
  updatedOn: Date;
  body: string;
  isRead: boolean;
  isEphemeral: boolean;
  sender: string;
  resource: string;
}

export interface PaginatedTableResponse<T> {
  records: T[];
  token: string | null;
}

export interface UserNotificationCounts {
  totalNotifications: number;
  unreadNotifications: number;
}

export type AnyObject = Record<string, unknown>;
export interface IDomainMessage {
  type: DomainEventName | string;
  data: AnyObject;
  context: IContext;
}
export function isDomainMessage(val: IDomainMessage | any): val is IDomainMessage {
  return (val as unknown as IDomainMessage).context !== undefined;
}

export function anyObjectTo<T>(input: AnyObject): T {
  return input as unknown as T;
}

export interface IContext {
  correlationId: string;
  causationId: string;
  userId: string;
  tenantId: string;
}

export type AccessManagementRecords = UserRole;
export interface PageRequest {
  tenantId?: string;
  perPage: number;
  pageNum: number;
  sortFilterContext?: SortFilterContext;
}

export interface PageResponse<T> {
  currentPage: number;
  records: T[];
  total: number;
}

export type LedgerRecord = {
  tenantId: string;
  userId: string;
  timestamp: Date;
  ts: Date;
  tokenCount: number;
  balance: number;
  userEmail: string;
};

export type AllocationDeallocationtRecord = LedgerRecord & {
  contractNumber: string;
  catalogNumber: string;
};

export type DebitRecord = LedgerRecord & {
  nantId: string;
  serviceKind: AppId;
  userId: string;
  duration: string;
  planId: string;
  reportWindow: Date;
  consumption: number;
  project?: string;
  resource?: string;
  unit: string;
  discountAmt?: number;
  discountPlans?: string;
  reasonCode?: string;
  startingBalance: number;
  tokensDeducted: number;
};

export enum Case {
  Credit = 'Credit',
  Debit = 'Debit',
  Adjustment = 'Adjustment',
  Deallocation = 'Deallocation',
}

export enum UserType {
  NewUser = 'NewUser',
  ServiceUser = 'ServiceUser',
  NormalUser = 'NormalUser',
}

export type CreditEntry = {
  Case: Case.Credit;
  Item: AllocationDeallocationtRecord;
  tokenConsumptionMapping?(): void;
};

export type DebitEntry = {
  Case: Case.Debit;
  Item: DebitRecord;
  tokenConsumptionMapping?(): void;
};

export type AdjustmentEntry = {
  Case: Case.Adjustment;
  Item: DebitRecord;
  tokenConsumptionMapping?(): void;
};

export type DeallocationEntry = {
  Case: Case.Deallocation;
  Item: AllocationDeallocationtRecord;
  tokenConsumptionMapping?(): void;
};

export type LedgerEntry = CreditEntry | DebitEntry | AdjustmentEntry | DeallocationEntry;

export interface LedgerRequest {
  tenantId: string;
  from: Date;
  duration: number;
  forExport: boolean;
  perPage?: number;
  token?: string | null;
}

export interface Entitlement {
  id: string;
  catalogNumber: string;
  contractNumber: string;
  status: string;
  name: string;
  description: string;
  serviceKind: AppId | '' | UniversalCredits;
  effectiveDate: Date;
  expiryDate: Date;
  primaryEmail: string;
  consumed?: number;
  userId: string;
  tenantId: string;
  serviceId: string;
  fromHybris: boolean;
  quantity: number;
  isSystemGenerated: boolean;
  attributes: Attributes;
  available: number;
  timestamp: Date;
  allocated: number;
  isTrialEntitlement?: boolean;
}

export interface TokenEntitlement extends Entitlement {
  entitlementId: string;
}
export interface AGGridEntitlement extends Entitlement {
  dateToText(): void;
  allocateButtonToText(): void;
  detailsServiceDataToText(): void;
  detailServiceDataHeaders: { header: string; subheader: string };
  productFamily: string;
  entitlementType: string;
}

export enum EntitlementBadge {
  addon = 'Add-On',
  platform = 'Platform',
  additive = 'Additive',
  trial = 'Trial',
  utilityToken = 'Universal Credits',
  newtrial = 'Trial',
}

export interface Attributes {
  combineType: string;
  campaignId?: string;
}

export interface TenantUtilityTokens {
  id: string;
  tokenBalance: number;
  tokenExpiration: Date;
  tokenEntitlements: Entitlement[];
  disabledTokenEntitlements: Entitlement[];
}

export interface TenantUtilityTokensResponse {
  id: string;
  tokenBalance: number;
  tokenExpiration: Date;
  tokenEntitlements: TokenEntitlement[];
  disabledTokenEntitlements: TokenEntitlement[];
}

export interface CatalogCode {
  catalogCode: string;
  serviceKind: AppId;
  name: string;
}

export type Invitation = Resource & {
  id: string;
  toEmail: string;
  acceptedByUser: string;
  acceptedByUserName: string;
  createdBy: string;
  createdByUserName: string;
  sentDate: Date;
  tenantId: string;
  role: Role;
  status: string;
  expiry: Date;
  buttonsConfig?: any;
};

export interface SnackBarData {
  message: string;
  type: string;
  title?: string;
  duration?: number;
  position?: string;
  customBtn?: CustomBtn;
}

export interface CustomBtn {
  label: string;
  navigateTo: string;
}

export interface SnackBarMessage {
  data?: SnackBarData;
}

export interface RemoveAccess {
  tenantId: string;
  userId: string;
  resourceId: string;
}

export interface AgGridState {
  refreshAgGrid?: boolean;
}

export enum ApplicationCardAction {
  LOGO = 'logo',
  FAV = 'fav',
  INFO = 'info',
  LINK = 'link',
}

export interface ApplicationCardEvent {
  card: ApplicationCard;
  action: ApplicationCardAction;
}

export interface LogoUrl {
  url: string;
}

export enum UploadLogoPreOperation {
  None = 'None',
  CreateTenant = 'CreateTenant',
  UpdateTenant = 'UpdateTenant',
  UpdateTenantName = 'UpdateTenantName',
}

export enum MessageIcons {
  Info = 'info-blue.svg',
  Success = 'check-circle-green.svg',
  Error = 'client-error-red.svg',
  Warning = 'warning-yellow.svg',
  LicenseScroll = 'license-scroll.svg',
}
export type ApproveUserRecord = Resource & {
  id: string;
  userId: string;
  userName: string;
  userEmail: string;
  tenantId: string;
  created: string;
  status: string;
  error: string;
};

export interface ErrorContext {
  url: string;
  message: string;
  correlationId?: string;
}

export interface AccessUser {
  userId: string;
  userName: string;
  userEmail: string;
  tenantId?: string;
}

export interface ServiceVisibility {
  serviceKind: AppId;
  hidden: boolean;
}

export interface ServiceMapItem {
  appId: string;
  appName: string;
  serviceKind: AppId;
  hidden: boolean;
}

export interface Service {
  serviceId: string;
  kind: AppId;
  owner: string;
  entitlements: Entitlement[];
}

export interface DisabledService extends Service {
  disabledReason: string;
}

export interface TenantService extends Service {
  canAccess: boolean;
  role: string;
  provisioned: boolean;
}

export interface UserTenant {
  userId: string;
  tenantId: string;
}
export interface EntitlementDataState {
  pendingServices?: PendingService[];
}

export interface PendingService {
  serviceId: string;
  kind: AppId;
  entitlement: Entitlement | null;
}

export type EntitlmentService = DisabledService | Service;

export interface PendingServicePayload {
  serviceId: string;
  kind: {
    Case: string;
  };
}
export interface PendingServiceV2 {
  service: PendingService;
  transaction: EntitlementTransactionInfo;
  userId: string;
}
export interface EntitlementTransactionInfo {
  entitlementId: string;
  entitlement: Entitlement;
  userId: string;
  tenantDetails: TenantAttributesDto;
  quantity: number;
  timestamp: Date;
}
export interface TenantAttributesDto {
  tenantId: string;
  isPersonal: boolean;
}
export interface EntitlementData {
  entitlement: Entitlement;
  app: ApplicationCard;
  serviceId?: string;
  tenantId: string;
  userId: string;
  continue?: boolean;
}

export enum RASymbols {
  MINUS = '—',
}

export enum EntitylementTypes {
  addOns = 'addon',
  trial = 'trial',
  platform = 'platform',
  additive = 'additive',
  utilityToken = 'utilityToken',
}

export interface BadgeInfo {
  color: string;
  text: string;
}

export const TOKEN_BALANCE_FRACTION_DIGITS = 3;

export enum EntitlementActionOptions {
  Full = 'Full',
  Partial = 'Partial',
}

export enum EntitlementAllocationModalDisplayModes {
  allocate = 'allocate',
  review = 'review',
}

export enum EntitlementAllocationModalActions {
  ReviewAllocation = 'Review Allocation',
  Cancel = 'Cancel',
  ConfirmAllocation = 'Confirm Allocation',
  GoBack = 'Go Back',
}

export enum EntitlementDeallocationModalActions {
  Cancel = 'Cancel',
  DeallocateEntitlement = 'Deallocate Entitlement',
}

export enum EntitlementStatus {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum InvitationsStatus {
  ACCEPTED = 'Accepted',
  ABORTED = 'Aborted',
  INVALID = 'Invalid',
  EXPIRED = 'Expired',
  ACTIVE = 'Active',
}

export interface AgGridEntitlementColumnsFormat {
  header: string;
  subheader: string;
}

export interface IGainsightUserIdentity {
  id: string;
  domain: string;
  email: string;
  company: string;
  raBusinessPartnerID: string;
  mfa: boolean;
}

export interface IGainsightOrganizationContext {
  organizationId: string;
  organizationName: string;
}

export enum IGainsightCustomEventName {
  ApplicationCard = 'CardClicked',
  ApplicationCardInfo = 'CardInfoClicked',
  EntitlementApplied = 'EntitlementApplied',
  TrialRedeemed = 'TrialRedeemed',
  PostLoginUnallocatedEntitlements = 'PostLoginUnallocatedEntitlements',
}

export interface IGainsightCustomEvent {
  eventName: IGainsightCustomEventName;
  eventPayload: any;
}

export interface IGainsightTrialRedeemed {
  campaignId: string;
  trialReservationId: string;
  status: TrialStatus;
  durationInDays: number;
  expiresOn?: string;
  catalogNumber: string;
  contractNumber: string;
  quantity: number;
  organizationName: string;
  organizationId: string;
}
export interface CommandRequestData {
  commandRequest: CommandRequest;
  response: HttpResponse<object>;
}

export interface IDomainUpdateResponse {
  response: HttpResponse<object>;
  message: IDomainMessage;
}

export enum MultiRequestErrorState {
  NO_ERRORS,
  PARTIAL_ERRORS,
  ALL_ERRORS,
}

export type UserId = string & { isGuid: true };
export function toUserId(userId: string): UserId {
  return userId as UserId;
}

export type FlagValue = boolean | string | number | object | undefined;

export type FeatureFlag =
  | 'allow_fake_entitlements'
  | 'enable_theme_switcher'
  | 'pending_api_support'
  | 'allow_test_users'
  | 'enable_tech_support'
  | 'enable_deallocation'
  | 'enable_language_switcher'
  | 'tile_ftremote_activation'
  | 'tile_ppax_analytics'
  | 'tile_bpa'
  | 'tile_cds'
  | 'maintenance_window'
  | 'tile_motion_analyzer'
  | 'enable_date_sort_filter'
  | 'tile_elementary'
  | 'tile_advisor'
  | 'enable_sso_login'
  | 'tile_mepm';

export interface FeatureFlagUpdate {
  flag: FeatureFlag;
  value: FlagValue;
}

export type HarnessIdentifier = UserId | 'common-service-portal';

export interface FeatureFlagTarget {
  identifier: HarnessIdentifier;
  attributes: {
    CurrentTenantId?: string;
    CurrentTenantName?: string;
    // choice type for target
    Type: 'User' | 'Application';
    Domain?: string;
  };
}

export interface AllRecordsRequest {
  url: string;
  perPage?: number;
  httpParams?: HttpParams;
  headers?: HttpHeaders;
}

export type FilterType =
  | 'greaterThanOrEqual'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'lessThan'
  | 'equals'
  | 'notEqual'
  | 'inRange'
  | 'contains';

export type FilterContext = {
  filterType: 'set' | 'number' | 'text' | 'date';
  type?: FilterType;
  filter?: any;
  filterTo?: any;
  dateTo?: Date;
  dateFrom?: Date;
  values?: string[];
};

export type FilterModel = {
  [key: string]: FilterContext;
};

export type AgFilterContext = {
  filterType: 'set' | 'number' | 'text' | 'date';
  type?: FilterType;
  filter?: any;
  filterTo?: any;
  dateTo?: string;
  dateFrom?: string;
  values?: (string | null)[];
};

export type AgFilterModel = {
  [key: string]: AgFilterContext;
};

export type SortModel = {
  colId: string;
  sort: 'asc' | 'desc';
};
export interface SortFilterContext {
  sortModel: SortModel[];
  filterModel: FilterModel;
}

export interface ErrorMessageMap {
  [httpStatus: number]: string;
}

export interface IMaintenanceWindow {
  from: DateTime;
  duration: number;
  get to(): DateTime;
  get isActive(): boolean;
  startsWithinDays(days: number): boolean;
}

export interface CreateTenantSuccess {
  newTenantId: string;
  blobImage: Blob;
  servicesVisibilityRequestBody: ServiceVisibility[];
}

export interface ReservationInfo {
  campaignId: string;
  trialReservationId: string;
}
export interface RedeemTrial {
  campaignId: string;
  trialReservationId: string;
  trialReservationDetails: TrialReservation;
}

export type TrialStatus = 'redeemed' | 'expired' | 'active' | 'cancelled';

export interface TrialReservation {
  id: string;
  campaignId: string;
  status: TrialStatus;
  partitionId: string;
  durationInDays: number;
  expiresOn?: string;
}

export enum AppFlow {
  Dasbboard = 'Dashboard',
  Invitation = 'Invitation',
  RedeemTrial = 'RedeemTrial',
}
export interface RouteState {
  flow: AppFlow;
  snapshot?: SerializedRouterStateSnapshot;
}

export enum BannerType {
  MaintenanceWindow = 'MaintenanceWindow',
  NegativeTokenBalance = 'NegativeTokenBalance',
  SessionTimeout = 'SessionTimeout',
}

export const defaultLanguage = 'en-US';
