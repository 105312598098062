import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SnackBarFacade, UserDataFacade } from '@ra-state';
import { asyncScheduler, filter, Observable, scheduled, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResourceRoleGuard {
  constructor(
    private userDataFacade: UserDataFacade,
    private router: Router,
    private snackBarFacade: SnackBarFacade,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.userDataFacade.effectiveRoles$.pipe(
      filter((effectiveRoles) => effectiveRoles !== undefined),
      switchMap((effectiveRoles) => {
        const hasPermission = effectiveRoles?.find((item) => route.data.roles?.includes(item.role));
        if (hasPermission) {
          return scheduled<boolean>([true], asyncScheduler);
        } else {
          const message = 'Insufficient Permissions. Redirecting to dashboard';
          this.snackBarFacade.displayMessage({ type: 'Error', message: message });
          this.router.navigate(['/dashboard']);
          return scheduled<boolean>([false], asyncScheduler);
        }
      }),
    );
  }
}
