<ra-ui-text-button
  *ngIf="params?.showEditbtn"
  class="access-btn"
  [config]="{
    displayIconAfterText: false,
  }"
  icon="ra-icon-ide-sm-edit"
  label="Edit"
  [disabled]="editDisabled"
  [raUiTooltip]="params?.editTooltip"
  [tooltipConfig]="params?.tooltipConfig"
  (click)="btnClickedHandler('edit', $event)"
>
</ra-ui-text-button>

<ra-ui-text-button
  *ngIf="showRemoveBtn && !isRemoveAccessResource(params?.data)"
  class="access-btn"
  [config]="{
    displayIconAfterText: false,
  }"
  icon="ra-icon-ide-sm-delete"
  label="Remove"
  [disabled]="removeDisabled"
  [raUiTooltip]="params?.deleteTooltip"
  [tooltipConfig]="params?.tooltipConfig"
  (click)="btnClickedHandler('remove', $event)"
>
</ra-ui-text-button>

<ra-ui-text-button
  *ngIf="isRemoveAccessResource(params?.data)"
  class="access-btn"
  [config]="{
    displayIconAfterText: false,
  }"
  icon="ra-icon-ide-md-undo"
  label="Undo"
  (click)="btnClickedHandler('undoRemoveAccess', $event)"
>
</ra-ui-text-button>
