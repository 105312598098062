import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IInnerButton, IInnerButtonClickedEvent } from '@ra-web-tech-ui-toolkit/components';
@Component({
  selector: 'app-error',
  styleUrls: ['./error.component.scss'],
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() buttons: IInnerButton[];
  @Output() buttonClick = new EventEmitter();

  onButtonClick(event: IInnerButtonClickedEvent): void {
    this.buttonClick.emit(event);
  }
}
