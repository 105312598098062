import { createAction, props } from '@ngrx/store';
import { IGainsightCustomEvent } from '../lemans-app.model';
export const resetSession = createAction('[GAINSIGHT] Reset/Logout Session ');

export const pushCustomEvent = createAction(
  '[GAINSIGHT] Push Custom Event',
  props<{ gainsightCustomEvent: IGainsightCustomEvent }>()
);

export const pushUsersActiveEntitlements = createAction(
  '[USERS_ENTITLEMENTS] Get Users Active Unallocated Entielements',
  props<{ value: string }>()
);
