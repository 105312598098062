<lib-common-mat-navbar class="app-navbar-container">
  <div class="full-screen-container">
    <div class="error-container">
      <div class="error-content">
        <span><i class="ra-icon-ide-lg-error ra-common-icon"></i></span>
        <span class="first-span-text">There was an error with the authorization.</span>
        <span class="second-span-text">Please contact Rockwell Technical Support to resolve</span>
      </div>
    </div>
  </div>
</lib-common-mat-navbar>
