<mat-form-field
  appearance="fill"
  class="email-container"
  [ngClass]="invalidEmailIdFound ? 'mat-form-field-invalid' : ''"
>
  <label>Enter emails</label>
  <ra-ui-chip-list
    id="basicChipListExample"
    [chipItems]="chipItems"
    (removedChips)="removeEmail($event)"
    class="email-input"
  ></ra-ui-chip-list>
  <input
    label="'Value of chip'"
    #emailInput
    matInput
    [(ngModel)]="newChipValue"
    (blur)="addEmail($event.target)"
    (keyup.space)="addEmail($event.target)"
    (keyup.enter)="addEmail($event.target)"
    id="chipValueInput"
    class="email-input"
  />
</mat-form-field>
<ra-ui-error-message
  *ngIf="invalidEmailIdFound"
  [message]="'Please enter valid emails'"
  class="email-error-message"
></ra-ui-error-message>
