<lib-common-mat-navbar
  class="app-navbar-container"
  [initialTheme]="navbarTheme"
  [logotypeClass]="logoTypeThemeClass"
  (logoutClick)="logoutClicked()"
>
  <div class="full-screen-container">
    <app-view-title
      [title]="'License Agreement'"
      [subTitle]="'Please read license agreements. By selecting Accept, you agree to the terms:'"
    ></app-view-title>
    <div class="eula-content-container">
      <ng-scrollbar #scroll class="scroll-event" thumbClass="white-scrollbars">
        <div class="eula-content" [innerHTML]="eulaContent$ | async"></div>
      </ng-scrollbar>
    </div>
    <div class="footer-container">
      <div class="eula-accept-text" *ngIf="(isEulaAccepted$ | async) === false">
        <span class="caption">By Selecting 'ACCEPT', you agree to the terms.</span>
      </div>
      <ra-ui-main-button
        *ngIf="(isEulaAccepted$ | async) === false"
        class="margin-right-16px"
        label="Accept"
        (click)="acceptEULA()"
        [disabled]="scroll !== undefined && (isScrolledToBottom$ | async) !== true"
      >
      </ra-ui-main-button>
      <ra-ui-outlined-button
        *ngIf="(isEulaAccepted$ | async) === false"
        label="Decline"
        (click)="declineEULA()"
      ></ra-ui-outlined-button>
      <ra-ui-main-button
        *ngIf="(isEulaAccepted$ | async) === true"
        class="margin-right-16px"
        label="Go to Dashboard"
        (click)="goToDashboard()"
      >
      </ra-ui-main-button>
    </div>
  </div>
</lib-common-mat-navbar>
<ng-container *ngIf="(authService.isAuthenticated$ | async) && (onHubConnected$ | async)"> </ng-container>
