/**
 * TO DO: review for dead code
 */

import { MessageIcons, Role } from '@ra-state';

export interface MessageIconMap {
  [key: string]: { icon: MessageIcons; message: string };
}

export class CommonConstants {
  // Session Timeout Configuration
  public static idealTimeout: number = 1500000; //25 Minutes
  public static sessionTimeout: number = 180; // 3 Minute

  public static responseMessageIconColorMap: MessageIconMap = {
    'add-access': { icon: MessageIcons.Success, message: 'Access has been added successfully.' },
    'duplicate-add-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with the selected user.',
    },
    'duplicate-edit-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with this user.',
    },
    'edit-access': { icon: MessageIcons.Success, message: 'Access has been modified successfully.' },
    'invite-user': { icon: MessageIcons.Error, message: 'Error - Email address you entered is not valid' },
    'license-agreement': { icon: MessageIcons.LicenseScroll, message: "Scroll to enable 'Accept' button" },
    'remove-access': { icon: MessageIcons.Info, message: 'Access has been removed successfully.' },
  };

  // Help Screen Data
  public static CONTACT_SUPPORT_URL = 'https://rockwellautomation.custhelp.com/app/chat/chat_landing/contacts.email/';
  static entitlementNewPeriodInDays = 3;

  public static getEmailDomain(emailString: string): string {
    return emailString.split('@')[1];
  }

  public static cleanLeftTrailingZeros = (str: string): string => {
    return str.replace(/^0+/, '');
  };

  public static UserIDPConnections = {
    MyRockwell: 'MyRockwell',
  };
}

export const SELECTABLE_ROLES = [Role.Admin, Role.Contributor];

export const ErrorMessageMap: MessageIconMap = {
  EntitlementAlreadyConsumed: { message: 'Entitlement already consumed', icon: MessageIcons.Error },
  EntitlementTransactionInProgress: {
    message: 'Service provisioning is in progress, please complete the provisioning before applying the entitlement',
    icon: MessageIcons.Error,
  },
  'EntitlementError-InactiveEntitlement': {
    message: 'Cannot allocate, the entitlement is no more active',
    icon: MessageIcons.Error,
  },

  'EntitlementError-platform entitlements cannot overlap': {
    message: 'Service already has a platform entitlement allocated for the time period',
    icon: MessageIcons.Error,
  },
  CannotApplyEntitlementToPersonalTenant: {
    message: 'Entitlements cannot be allocated to a personal organization',
    icon: MessageIcons.Error,
  },
  EntitlementNotActive: { message: 'Entitlement is not active', icon: MessageIcons.Error },
  InvitationAlreadyExists: { message: 'Invitation for the user already exists', icon: MessageIcons.Warning },
  MinimumTimeBetweenInvitationReissueNotSatisfied: {
    message: 'The invite was already sent.',
    icon: MessageIcons.Error,
  },
  UserAlreadyHasAccessToSpecifiedResource: {
    message: 'Your account already has access to the organization',
    icon: MessageIcons.Error,
  },
  'EntitlementError-Platform/Trial/NewTrial/Additive entitlement needs to applied for activating the service': {
    message: 'Platform/Additive entitlement needs to applied before applying an Add-on entitlement',
    icon: MessageIcons.Error,
  },
  'EntitlementError-Entitlement kind disallows quantity more than 1': {
    message: 'Only one entitlement of type Platform can be allocated at a time',
    icon: MessageIcons.Error,
  },
  InvitationAlreadyAccepted: {
    message: 'Invitation is already accepted for this organization.',
    icon: MessageIcons.Error,
  },
  InvitationAlreadyCancelled: {
    message: 'Invitation is cancelled. Please contact your administrator.',
    icon: MessageIcons.Error,
  },
  InvitationExpired: {
    message: 'Invitation is expired. Please contact your administrator.',
    icon: MessageIcons.Error,
  },
  'invitation not in active state': {
    message: 'Invitation is not active. Please contact your administrator.',
    icon: MessageIcons.Error,
  },
};

export class ErrorPartMessageMapper {
  static ErrorPartMessageMap: MessageIconMap = {
    'does not overlap platform entitlement': {
      message: 'Add-On entitlement dates does not overlap with platform entitlement dates',
      icon: MessageIcons.Error,
    },
  };
  public static getMessageIconMap(errorCode: string): { icon: MessageIcons; message: string } | undefined {
    const errorPartKeys: string[] = Object.keys(this.ErrorPartMessageMap);
    const foundErrorPartKey = errorPartKeys.find((errorPart) => errorCode.match(errorPart));
    return foundErrorPartKey ? this.ErrorPartMessageMap[foundErrorPartKey] : undefined;
  }
}
