<div class="catalog-dialog">
  <div mat-dialog-content class="catalog-dialog-content">
    <div class="title">Purchase Fake Entitlements</div>

    <div class="form">
      <ra-ui-input [formControl]="searchText" label="Search" name="searchText" ngDefaultControl>
        <span class="ra-ui-input-suffix">
          <button
            mat-button
            *ngIf="searchText.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchText.setValue('')"
          >
            <i class="ra-icon-ide-sm-cross align-middle"></i>
          </button>
        </span>
      </ra-ui-input>

      <mat-selection-list #catSelection (selectionChange)="selectionChange($event.options)">
        <mat-list-option
          [checkboxPosition]="position"
          *ngFor="let catalogCode of search$ | async"
          [value]="catalogCode.catalogCode"
          [selected]="selectedCodesControl.value && selectedCodesControl.value?.indexOf(catalogCode.catalogCode) >= 0"
        >
          <p class="body-2-bold" matline>{{ catalogCode.name }}</p>
          <span class="body-2" matline>{{ catalogCode.serviceKind }} | Catalog: {{ catalogCode.catalogCode }} </span>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <div class="btn-container">
    <ra-ui-main-button
      class="margin-12"
      label="Add {{ selectedCodesControl.value?.length }} entitlements"
      (click)="ok()"
      [disabled]="catSelection.selectedOptions.isEmpty()"
    >
    </ra-ui-main-button>
    <ra-ui-outlined-button label="Cancel" (click)="cancel()"></ra-ui-outlined-button>
  </div>
</div>
