import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControlPageFacade } from '@ra-state';
import { HttpMethod } from '@auth0/auth0-angular';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private cache = new Map<string, any>();
  timerId: any;

  constructor(private controlPageFacade: ControlPageFacade) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.method !== HttpMethod.Get) {return next.handle(req);}

    const showSpinner = req.headers.get('show_spinner');
    if (showSpinner === null) {
      this.controlPageFacade.setLoading(true);
    } else {
      this.controlPageFacade.setLoading(false);
    }

    this.cache.set(req.url, req.method);
    if (this.cache.size > 0) {
      clearTimeout(this.timerId);
    }
    req.context['customtimer'] = req.headers.get('customtimer');
    req.context['customtimer'] = req.context['customtimer'] ? req.context['customtimer'] : 1000;
    return next.handle(req).pipe(
      finalize(() => {
        this.cache.delete(req.url);
        if (this.cache.size === 0) {
          this.timerId = setTimeout(() => {
            this.controlPageFacade.setLoading(false);
          }, req.context['customtimer']);
        }
      })
    );
  }
}
