import { Injectable } from '@angular/core';
import { AuthService } from '@rockwell-automation-inc/service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private authService: AuthService) {}

  isAuthenticated$ = this.authService.isAuthenticated$;
  user$ = this.authService.user$;

  login(targetPath: string, allowTestUsers: boolean = false): void {
    if (allowTestUsers) {
      this.authService.login(targetPath);
    } else {
      this.authService.loginWithoutPopup(targetPath);
    }
  }

  loginSSO(connection: string, targetPath: string): void {
    this.authService.loginSSO(connection, targetPath);
  }

  logout(): void {
    this.authService.logout();
  }

  checkAuth0Session(): Promise<boolean> {
    return this.authService.checkAuth0Session();
  }
}
