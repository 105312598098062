import { createSelector, select, Store } from '@ngrx/store';
import { AppState, SignalRData } from '../lemans-app.model';
import { HubConnectionState } from '@microsoft/signalr';
import { distinctUntilChanged, filter, Observable, takeWhile, timeout } from 'rxjs';
const stateSelector = (state: AppState): SignalRData => state.signalR;
export const selectSignalRState = createSelector(stateSelector, (state: SignalRData) => state);
export const selectDomainMessage = createSelector(selectSignalRState, (state: SignalRData) => state.domainMessage);

// we need selectors with filters applied to them... doing this way hides knowledge of how we inspect that state
// ref: https://stackoverflow.com/a/48668350
export function isDisonnected$(store$: Store<AppState>): Observable<SignalRData> {
  return store$.pipe(
    select(selectSignalRState),
    filter((state: SignalRData) => state.connectionState === HubConnectionState.Disconnected),
    distinctUntilChanged(),
  );
}

export function ensureIsConnectedSelector$(store$: Store<AppState>, maxWait: number): Observable<any> {
  /// when disconnected, wait at max for maxwait millis
  return store$.pipe(
    select(selectSignalRState),
    filter((state: SignalRData) => state.connectionState !== HubConnectionState.Disconnected),
    timeout(maxWait),
    takeWhile((state: SignalRData) => state.connectionState !== HubConnectionState.Connected),
  );
}
export function isConnected$(store$: Store<AppState>, _maxWait?: number): Observable<SignalRData> {
  return store$.pipe(
    select(selectSignalRState),
    filter((state: SignalRData) => state.connectionState === HubConnectionState.Connected),
    distinctUntilChanged(),
  );
}
