import { routerReducer } from '@ngrx/router-store';
import { agGridReducer } from './ag-grid';
import { signalrReducer } from './signalR';
import { controlPageReducer } from './control-page';
import { setUserData } from './user-data';
import { featureFlagsReducer } from './feature-flags';
import { snackbarDataReducer } from './snackbar/snackbar.reducer';

export const reducers = {
  agGrid: agGridReducer,
  controlPage: controlPageReducer,
  router: routerReducer,
  signalR: signalrReducer,
  userData: setUserData,
  featureFlags: featureFlagsReducer,
  snackBarData: snackbarDataReducer,
};
