import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { AG_RENDER_ACTIONS, AG_RENDER_BUTTON_OPTIONS } from '../ag-grid.models';
import { AgTwoButtonConfig } from '../ag-models';

@Component({
  styleUrls: ['./ag-two-buttons-render.component.scss'],
  templateUrl: './ag-two-buttons-render.component.html',
})
export class AgTwoButtonRenderComponent implements ICellRendererAngularComp {
  params: any;
  AG_RENDER_ACTIONS = AG_RENDER_ACTIONS;
  AG_RENDER_BUTTONS = AG_RENDER_BUTTON_OPTIONS;

  buttonsConfig: AgTwoButtonConfig;

  agInit(params: any): void {
    this.params = params;
    this.buttonsConfig = this.params.data.buttonsConfig;
  }

  // Optional - Return true if you want to manage a cell refresh yourself, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create  a new component in its place
  // with the new values.
  refresh(): boolean {
    return false;
  }

  buttonAction(action: AG_RENDER_ACTIONS): void {
    this.params.agRenderClickHandler(action, this.params.data);
  }
}
