// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/* eslint-disable sonarjs/no-duplicate-string */

import { Configuration } from '@app/models/config.model';
import { createEnvConfig } from './applications';
import { ApplicationCard, PreviewAppId } from '@ra-state';
import { AppId } from '@rockwell-automation-inc/common-utils';

// NOTE: The appId attribute below needs to match the appId value in the applications.ts file
const APPS: Partial<ApplicationCard>[] = [
  {
    appURL: 'https://vault.lemans-sandbox.rockwellautomation.com/vault?tenantId=${tenantId}',
    appId: AppId.Vault,
  },
  {
    appURL: 'https://ftra-wizard.lemans-sandbox.rockwellautomation.com/auto-sign-in?tenantId=${tenantId}',
    appId: AppId.SecureRemoteAccess,
  },
  {
    appURL:
      'https://lp.fiixsoftware.com/fiix-factorytalk-hub.html?utm_source=rockwell&utm_medium=factorytalk&utm_campaign=other',
    appId: PreviewAppId.Fiix,
  },
  {
    appURL: 'https://home.ftds-dev.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.IDE,
  },
  {
    appURL: 'https://sample.lemans:44479/signin?tenantId=${tenantId}',
    appId: AppId.FooService,
    provisioningUrl: 'https://sample.lemans:44479/complete-service-provisioning',
  },
  {
    appURL: 'https://www.plex.com/',
    appId: PreviewAppId.Plex,
  },
  {
    appURL: 'https://twinstudio.lemans-sandbox.rockwellautomation.com/?tenantId=${tenantId}',
    appId: AppId.TwinStudio,
  },
  {
    appURL: 'https://optix.lemans-sandbox.rockwellautomation.com/dashboard/?tenantId=${tenantId}',
    appId: AppId.FTOptix,
  },
  {
    appURL: 'https://dev.assetintelligence.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.AssetPerformanceMonitor,
  },
  {
    appURL: 'https://datamosaix-portal.lemans-sandbox.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.DataMosaix,
  },
  {
    appURL: 'https://dev.ftem.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.EnergyManager,
  },
  {
    appURL: 'https://dev.bpa.rockwellautomation.com/?tenantId=${tenantId}',
    appId: AppId.BatchPerformanceAnalytics,
  },
  {
    appURL: 'https://ftac-sandbox.699bc7e377f444528c91.southeastasia.aksapp.io/?tenantId=${tenantId}',
    appId: AppId.AssetCenter,
  },
  {
    appURL: 'https://dev.plantpaxanalytics.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.PPaxAnalytics,
  },
  {
    appURL: 'https://cds.lemans-sandbox.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.CDS,
  },
  {
    appURL: 'https://ftma-int.azurewebsites.net',
    appId: PreviewAppId.FactoryTalkMotionAnalyzer,
  },
  {
    appURL: 'https://visionai-sandbox.elementaryml.com',
    appId: AppId.Elementary,
  },
  {
    appURL: 'https://advisordev.rockwellautomation.com',
    appId: PreviewAppId.Advisor,
  },
  {
    appURL: 'https://dev.ftmepm.rockwellautomation.com',
    appId: AppId.MEPM,
  },
];

const appConfig: Partial<Configuration> = {
  production: false,
  name: 'home-lemans-sandbox',
  csApiBaseUrl: '',
  csPortalUrl: 'https://home.lemans-sandbox.rockwellautomation.com',
  notificationsBaseUrl: '/notifications',
  techSupportUrlPath: '/p/5794/c/5346',
  commercePortalUrl: 'https://commerce-puat.rockwellautomation.com',
  customSignInUrl: 'https://home.lemans-sandbox.rockwellautomation.com/sign-in',
  authConfig: {
    domain: 'factorytalkhub-dev.us.auth0.com',
    clientId: 'buP4hbEpJjQibdmIouGrJ9OwGwwQ58qJ',
    audience: 'https://lemans.common',
    scope: 'email read:user',
    useRefreshToken: true,
    cacheLocation: 'memory',
    connection: 'MyRockwellSaml',
  },
  isForCSPortal: true,
  rockwellAuthConfig: {
    rockwellAuth0ClientId: 'loSKNRkmj8vwwCIa74A7gbJxrYrDNs86',
    rockwellAuth0Domain: 'rockwell-qa.us.auth0.com',
  },
  supportedLanguages: [
    {
      langText: 'English',
      langCode: 'en-US',
    },
    {
      langText: 'Spanish',
      langCode: 'es-US',
    },
  ],
};
// env overrides from index-html-transform.ts OR server side node js app (server/index.ts)
export const environment = createEnvConfig(appConfig, APPS);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
