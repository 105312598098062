import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, distinctUntilChanged, delayWhen, timer, filter } from 'rxjs';
import { AppState, FeatureFlag, FlagValue, IMaintenanceWindow } from '../lemans-app.model';
import { selectFlags } from './feature-flags.selectors';
import { DateTime } from 'luxon';
import { LoggerService } from '@servicesV2/logger.service';

// TODO: only used in test directly - isn't there a better way to hide this class
export class MaintenanceWindow implements IMaintenanceWindow {
  from: DateTime;
  duration: number;
  constructor(json?: string, logger?: LoggerService) {
    try {
      const obj = (json && JSON.parse(json)) || {};
      logger?.debug('Raw and parsed flag value', json, obj);
      if (Object.keys(obj).length === 0) {
        this.duration = 0;
        this.from = DateTime.fromMillis(0);
      } else {
        this.duration = parseInt(obj.duration) || 0;
        this.from = DateTime.fromISO(obj.from);
        if (!this.from.isValid) {
          logger?.error('Failed to parse from date - ', this.from.invalidReason, this.from.invalidExplanation);
          this.from = DateTime.fromMillis(0);
        }
      }
    } catch (ex) {
      logger?.error(ex, 'Failed to parse flagValue', json);
      this.duration = 0;
      this.from = DateTime.fromMillis(0);
    }
  }

  get to(): DateTime {
    return this.from.plus({ minutes: this.duration });
  }
  get isActive(): boolean {
    const now = DateTime.now();
    return this.duration > 0 && now >= this.from && now <= this.from.plus({ minutes: this.duration });
  }
  startsWithinDays(days: number): boolean {
    const now = DateTime.now();
    return this.duration > 0 && now >= this.from.minus({ days: days }) && now <= this.from;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsFacade {
  flags$ = this.store$.select(selectFlags);

  constructor(private store$: Store<AppState>, private logger: LoggerService) {}

  getFlagValue$(flag: FeatureFlag): Observable<FlagValue> {
    return this.flags$.pipe(
      map((flags) => (flags ? flags[flag] : undefined)),
      distinctUntilChanged()
    );
  }

  maintenanceWindow$: Observable<IMaintenanceWindow> = this.getFlagValue$('maintenance_window').pipe(
    map((json: string) => new MaintenanceWindow(json, this.logger)),
    distinctUntilChanged()
  );

  /// sets up a delayed observable that triggers at the end time if the mw was active.
  maintenanceWindowCompleted$: Observable<any> = this.maintenanceWindow$.pipe(
    filter((mw) => mw.isActive),
    delayWhen((mw: IMaintenanceWindow, _idx) => timer(mw.to.toJSDate()))
  );
}
