import { Component } from '@angular/core';
import { UserDataFacade } from '@ra-state';

@Component({
  selector: 'app-navigation',
  styleUrls: ['./navigation.component.scss'],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent {
  currentCustomProvisioningData$ = this.userDataFacade.currentCustomProvisioningData$;
  constructor(private userDataFacade: UserDataFacade) {}
}
