<section class="content-modal-container">
  <section class="body-container" [ngClass]="classConfig">
    <ng-content></ng-content>
    <div *ngIf="buttonNames" class="btn-container">
      <ra-ui-main-button label="{{ buttonNames?.btn1 }}" (click)="btnAction(buttonNames?.btn1)"> </ra-ui-main-button>
      <ra-ui-outlined-button
        label="{{ buttonNames?.btn2 }}"
        (click)="btnAction(buttonNames?.btn2)"
      ></ra-ui-outlined-button>
    </div>
  </section>
</section>
