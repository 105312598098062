<div class="headline-6">Feedback</div>
<mat-dialog-content class="feedback-dialog-content">
  <p class="body-2">Dear User,<br />Your thoughts are valuable in helping improve our services.</p>
  <form #feedbackForm="ngForm" class="feedback-form">
    <mat-form-field>
      <mat-select
        #feedbackType="ngModel"
        #fdType
        name="feedbackType"
        required
        ngModel
        placeholder="Select Feedback Type"
      >
        <mat-option *ngFor="let feedbackOption of feedbackOptions" [value]="feedbackOption.type">
          {{ feedbackOption.type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ra-ui-error-message
      *ngIf="feedbackType.hasError('required')"
      [message]="'Feedback Option is required'"
    ></ra-ui-error-message>
    <div>
      <ra-ui-multiline-input
        #feedbackText
        ngModel
        label="Please share your feedback with us."
        [config]="{
          isAutoSizeTextArea: true,
          fillParent: true,
          removeMessageSpace: false,
          minRows: 5,
          maxRows: 15,
          maxLength: 1000
        }"
        [hintMessageEnd]="getInputHint(feedbackText.value, 1000)"
        [errorMessages]="{ required: 'Feedback is required', maxlength: 'Only 1000 characters allowed' }"
        name="feedbackText"
        ngDefaultControl
        required
      ></ra-ui-multiline-input>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="feedback-modal-action-container">
  <ra-ui-main-button label="Send" [disabled]="!feedbackForm.valid" (click)="sendFeedback()"> </ra-ui-main-button>
  <ra-ui-outlined-button label="Cancel" (click)="cancel()"></ra-ui-outlined-button>
</mat-dialog-actions>
