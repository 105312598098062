import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { isRemoveAccessResource } from '@app/v2/shared/utils';

@Component({
  selector: 'app-ag-remove-access-button',
  styleUrls: ['./ag-remove-access-button.component.scss'],
  templateUrl: './ag-remove-access-button.component.html',
})
export class AgRemoveAccessButtonComponent implements ICellRendererAngularComp {
  params: any;
  removeDisabled = false;
  editDisabled = false;
  showRemoveBtn = true;
  isRemoveAccessResource = isRemoveAccessResource;
  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.editDisabled = this.params.data.editDisabled;
    this.removeDisabled = this.params.data.removeDisabled;
    this.showRemoveBtn = this.params.isApproveUserFlow
      ? false
      : this.params.showRemoveBtn || this.params.data.role !== 'Owner';
  }

  btnClickedHandler(action: string, _event?: any): void {
    this.params.clicked(action, this.params.data);
  }
}
